import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    bold: {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "19px"
    }
});


const SingleEventinfoBlock = ({eventName, comments, eventCreatorName}) => {
    
    const classes = useStyles();

    return (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item>
                <Typography variant="h6">
                    {eventName}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">
                    <span className={classes.bold} >Comentarios:</span> {comments}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">
                    <span className={classes.bold} >Creador por:</span> {eventCreatorName}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default SingleEventinfoBlock;
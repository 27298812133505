import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EastIcon from "@material-ui/icons/ArrowRightAlt";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UserInfoValidationDialogTItle from "../controls/SupportConsoleControls/UserInfoValidationDialogTitle";
import UserInfoValidationContentPanel from "../controls/SupportConsoleControls/UserInfoValidationContentPanel";
import { makeStyles } from "@material-ui/core/styles";
import { grey, red, green } from "@material-ui/core/colors";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Controls from "../controls/searchInteractionControls/Controls";

const useStyles = makeStyles({
  rightSpacer: {
    marginRight: "2em",
  },
  bottomSpacer: {
    marginBottom: "2em",
  },
  dialogTitle: {
    fontWeight: "bold",
  },
  buttonSuccess: {
    color: grey[50],
    backgroundColor: "#66BB6A",
    "&:hover": {
      backgroundColor: "#66BB6A",
    },
  },
  buttonError: {
    color: grey[50],
    backgroundColor: "#D33604",
    "&:hover": {
      backgroundColor: "#D33604",
    },
  },
  cancelButton: {
    fontWeight: 400,
    color: "#FF995B",
    border: "none",
    backgroundColor: "inherit",
    textDecorationLine: "underline",
    padding: "10px 15px",
  },
  closeButton: {
    background: "#FF995B",
    "box-shadow": "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    border: "none",
    padding: "10px 16px",
    "border-radius": "4px",
  },
  closeButtonContainer: {
    display: "flex",
    "flex-grow": 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titleDialog: {
    borderBottom: "1px solid #E7E7E7",
  },
});

const UserInfoValidationDialog = ({
  displayDIalog,
  userInfoUpdateContextCallback,
  parentToogleShowThis,
  validationPopups,
  changeValidacion,
}) => {
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const loadFieldInfoListForValidation = () => {
    if (isEmpty(changeValidacion.dataValidacion) == false) {
      return [
        {
          label: "Correo electrónico",
          value: changeValidacion.dataValidacion["correo"],
        },
        {
          label: "Número telefónico",
          value: changeValidacion.dataValidacion["celular"],
        },
        {
          label: "Código postal",
          value: changeValidacion.dataValidacion["codigopostal"],
        },
        {
          label: "Delegación/Municipio",
          value: changeValidacion.dataValidacion["direccion"],
        },
      ];
    } else {
      return [
        {
          label: "Correo electrónico",
          value: "No tiene correo",
        },
        {
          label: "Número telefónico",
          value: "No tiene telefono",
        },
        {
          label: "Código postal",
          value: "No tiene registrado cp",
        },
        {
          label: "Delegación/Municipio",
          value: "No tiene registrado Delegación/Municipio",
        },
      ];
    }
  };

  const classess = useStyles();
  ////
  const siteNavigationHistory = useHistory();
  const { userId } = useParams();
  const { supportCaseId } = useParams();
  const MIN_VALIDATED_FIELD_COUNT_REQUIRED = 2;
  //
  const [isDialogOpen, setIsDialogOpen] = useState(displayDIalog || false);
  const [fieldInfoListForValidation, setFieldInfoListForValidation] = useState(
    []
  );
  const [currentValidationIndexField, setCurrentValidationIndexField] =
    useState(0);
  const [successfullyValidatedFieldCount, setSuccessfullyValidatedFieldCount] =
    useState(0);
  const [isValidationProcessCompleted, setIsValidationProcessCompleted] =
    useState(false);
  const [contador, setContador] = useState(0);
  const [ejecutar, setEjecutar] = useState(false);

  useEffect(() => {
    if (isDialogOpen) {
      setFieldInfoListForValidation(loadFieldInfoListForValidation());
      setEjecutar(true);
    }
  }, [isDialogOpen]);

  const validationDialogController = async () => {
    console.log(fieldInfoListForValidation);
    console.log(fieldInfoListForValidation.length);
  };

  useEffect(() => {
    if (ejecutar === true) {
      if (fieldInfoListForValidation && fieldInfoListForValidation.length > 0) {
        let isValidationProcessEnded =
          successfullyValidatedFieldCount ===
            MIN_VALIDATED_FIELD_COUNT_REQUIRED ||
          currentValidationIndexField > fieldInfoListForValidation.length - 1;

        if (isValidationProcessEnded) {
          setIsValidationProcessCompleted(true);

          if (
            successfullyValidatedFieldCount ===
            MIN_VALIDATED_FIELD_COUNT_REQUIRED
          ) {
            userInfoUpdateContextCallback(true);
          } else {
            userInfoUpdateContextCallback(false);
          }
        }
      }
      setEjecutar(false);
    }
  }, [
    currentValidationIndexField,
    successfullyValidatedFieldCount,
    fieldInfoListForValidation,
    userInfoUpdateContextCallback,
  ]);

  const handleOpenEvent = () => {
    setIsDialogOpen(true);
  };
  const baseUrl = process.env.REACT_APP_API_URL + "/support/console";
  const inserccionIntentoIncidencia = async (paestatus) => {
    try {
      //?pafolio=250521130&paestatus=0&pausrmodifico=23720
      const response = await axios({
        url:
          `${baseUrl}/incidencia/intentos/registro/?pafolio=` +
          supportCaseId +
          `&paestatus=` +
          paestatus +
          `&pausrmodifico=` +
          atob(userId),
        method: "POST",
        params: {},
        timeout: 500000,
      });
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseEvent = async () => {
    if (
      successfullyValidatedFieldCount < 2 &&
      isValidationProcessCompleted == true
    ) {
      console.log("Guardando...");
      await inserccionIntentoIncidencia(0);
      console.log("Regresando a la ventana de busqueda");
      siteNavigationHistory.push(`/interaction/dashboard/${userId}`);
    } else if (
      successfullyValidatedFieldCount === 2 &&
      isValidationProcessCompleted == true
    ) {
      console.log("Guardando...");
      await inserccionIntentoIncidencia(1);
    }
    setIsDialogOpen(false);
    parentToogleShowThis();
  };

  const handleFieldValidationEvent = (fieldSuccessValidationEventTriggered) => {
    setCurrentValidationIndexField(currentValidationIndexField + 1);
    setEjecutar(true);
    if (!isValidationProcessCompleted && fieldSuccessValidationEventTriggered) {
      setSuccessfullyValidatedFieldCount(successfullyValidatedFieldCount + 1);
      setEjecutar(true);
    }
  };

  const getDialogTitleForDisplay = () => {
    if (isValidationProcessCompleted) {
      if (
        successfullyValidatedFieldCount === MIN_VALIDATED_FIELD_COUNT_REQUIRED
      ) {
        return <UserInfoValidationDialogTItle titleType="STATUS_OK" />;
      } else {
        return <UserInfoValidationDialogTItle titleType="STATUS_ERROR" />;
      }
    }

    return <UserInfoValidationDialogTItle titleType="FIELD" />;
  };

  const getContentPanelForDisplay = () => {
    if (isValidationProcessCompleted) {
      if (
        successfullyValidatedFieldCount === MIN_VALIDATED_FIELD_COUNT_REQUIRED
      ) {
        return (
          <UserInfoValidationContentPanel
            contentType="STATUS_ok"
            fieldLabel=""
            fieldValidationValue=""
          />
        );
      } else {
        return (
          <UserInfoValidationContentPanel
            contentType="STATUS_ERROR"
            fieldLabel=""
            fieldValidationValue=""
          />
        );
      }
    } else {
      if (
        fieldInfoListForValidation &&
        fieldInfoListForValidation.length > 0 &&
        currentValidationIndexField <= fieldInfoListForValidation.length - 1
      ) {
        let fieldLabel =
          fieldInfoListForValidation[currentValidationIndexField].label || "";
        let fieldValue =
          fieldInfoListForValidation[currentValidationIndexField].value || "";
        console.log("contador ------------------>" + contador);
        return (
          <UserInfoValidationContentPanel
            contentType="FIELD"
            fieldLabel={fieldLabel}
            fieldValidationValue={fieldValue}
            currentIndexPanel={currentValidationIndexField}
            totalFIeldValidationListCount={fieldInfoListForValidation.length}
          />
        );
      }
    }

    return null;
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onEntering={handleOpenEvent}
        onClose={handleCloseEvent}
        open={isDialogOpen}
      >
        <DialogTitle
          style={
            !isValidationProcessCompleted
              ? {}
              : successfullyValidatedFieldCount ===
                MIN_VALIDATED_FIELD_COUNT_REQUIRED
              ? { borderBottom: "4px solid #66BB6A" }
              : { borderBottom: "4px solid #D33604" }
          }
        >
          {getDialogTitleForDisplay()}
        </DialogTitle>

        <DialogContent className={classess.rightSpacer} dividers>
          {getContentPanelForDisplay()}
        </DialogContent>
        <DialogActions style={{ height: "70px" }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="baseline"
            alignItems="center"
          >
            {!isValidationProcessCompleted && (
              <Grid item>
                <Button
                  className={classess.buttonError}
                  onClick={() => handleFieldValidationEvent(false)}
                  variant="contained"
                  startIcon={<CancelIcon />}
                >
                  No confirmado
                </Button>
              </Grid>
            )}
            {!isValidationProcessCompleted && (
              <Grid item>
                <Button
                  className={classess.buttonSuccess}
                  onClick={() => handleFieldValidationEvent(true)}
                  variant="contained"
                  startIcon={<CheckCircleIcon />}
                >
                  Confirmado
                </Button>
              </Grid>
            )}
            <Grid item container xs direction="row-reverse">
              {isValidationProcessCompleted ? (
                <div className={classess.closeButtonContainer}>
                  <Controls.Buttons
                    onClick={handleCloseEvent}
                    text={"Continuar"}
                    endIcon={<EastIcon />}
                  >
                    Continuar <EastIcon />
                  </Controls.Buttons>
                </div>
              ) : (
                <button
                  className={classess.cancelButton}
                  onClick={handleCloseEvent}
                >
                  Cancelar
                </button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserInfoValidationDialog;

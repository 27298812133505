import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import InteractionDashboard from "./webapp/pages/InteractionDashboard";
import SupportConsole from "./webapp/pages/SupportConsole";
import './webapp/assets/css/App.css'
import CssBaseline from '@material-ui/core/CssBaseline';

function App() {

    return (
        <React.Fragment>

            <CssBaseline />

            <div>

                <BrowserRouter>
                    <Switch>
                        <Route path={'/interaction/dashboard/:userId'} exact={true} component={InteractionDashboard}/>
                        <Route path={'/console/:userId/case/:supportCaseId'} exact={true} component={SupportConsole} />
                    </Switch>
                </BrowserRouter>
            </div>
        </React.Fragment>
    );
}

export default App;
import {transformResponseFuzzyTableFunction, transformResponseInputSelectFunction} from "./axiosUtilities";
export const axios = require('axios').default;

const WS_BASE_PATH = process.env.REACT_APP_API_URL + '/support/console';
const PARTIAL_PATH_GET_CATALOGS = '/catalogos/';
const PARTIAL_PATH_SEARCH_SUPPORT_CASES = '/folios/search';
const PARTIAL_PATH_REGISTER_NEW_INTERACTION = '/incidencia/registro';

export const CATALOG_VALID_OPTIONS_VALUES = { causas: "causa",
                                              medios: "medios",
                                              plataforma: "plataforma",
                                              prioridad: "prioridad",
                                              sistema: "sistema",
                                              tipificacion: "tipificacion",
                                              estatus: "estatus",
                                              nivel: "nivel",
                                              pais: "pais",
                                              poblacion: "poblacion" };


const applyResponseDataTransformationFromSettings = (targetData, transformationResponseSettings) => {

    try {
        if (transformationResponseSettings) {

            const transformationTargetKeys = Object.keys(transformationResponseSettings);

            const transformationTargetParams = (transformationTargetKeys &&
                transformationTargetKeys.length > 0) ?
                transformationResponseSettings[transformationTargetKeys[0]].params :
                undefined;
            if (transformationTargetParams !== undefined) {

                const targetData = {...targetData};
                const targetResponseData = {...targetData.responseMainData};
                const transformedData = {targetData, targetResponseData}

                switch (transformationTargetKeys[0]) {
                    case 'fuzzyTable': {
                        return transformResponseFuzzyTableFunction({...transformedData},
                            transformationTargetParams.columnValuesOrderTemplate,
                            transformationTargetParams.keyFieldName,
                            transformationTargetParams.includeKeyField);
                    }
                    case 'inputSelect': {
                        return transformResponseInputSelectFunction({...transformedData},
                            transformationTargetParams.keyFieldName,
                            transformationTargetParams.customKeyFieldName,
                            transformationTargetParams.descriptionFieldName,
                            transformationTargetParams.customDescriptionFieldName,
                            transformationTargetParams.orderByKeyValue,
                            transformationTargetParams.useAscOrder);
                    }
                }
            }
        }

        return targetData;
    } catch (error) {
        //const sourceReference = `<ApiMainService@applyResponseDataTransformationFromSettings>`;
        //console.log();
        //console.error(`${sourceReference} -----------`);
        //console.error(`${sourceReference} DATA ERROR`);
        //console.error(error);
        //console.error(`${sourceReference} -----------`);
    }

    return undefined;
}

const apiProcessRequest = async function({ inputRequestData, requestUrl, requestParamsData, transformationResponseSettings }) {

    try {
        const requestData = {...inputRequestData};

        const requestConfiguration = {
            method: 'post',
            url: requestUrl,
            baseUrl: WS_BASE_PATH,
            data: {...requestData},
            timeout: 5000,
            params: {...requestParamsData},
            transformResponse: [function(data) {
                return data;
            }]
        }

        const responseData = await axios(requestConfiguration);
        return responseData;

    } catch (error) {
        const sourceReference = `<ApiMainService@apiProcessRequest>`;
        console.log();
        console.error(`${sourceReference} -----------`);
        console.error(`${sourceReference} DATA ERROR`);
        console.error(error);
        console.error(`${sourceReference} -----------`);
    }
}

export async function getCatalogCollection(catalogNameRequested, catalogRequestData) {

    try {

        const catalogKeyFieldNames = { causas: "causas",
                                       medios: "medios",
                                       plataforma: "plataforma",
                                       prioridad: "prioridad",
                                       sistema: "sistema",
                                       tipificacion: "tipificacion",
                                       estatus: "estatus",
                                       nivel: "nivel",
                                       pais: "pais",
                                       poblacion: "poblacion" };

        const requestData = {...catalogRequestData};

        const requestParamsData = {
            tipo: catalogNameRequested
        };

        const transformationResponseSettings = {
            inputSelect: {
                params: {
                    keyFieldName: catalogKeyFieldNames[catalogNameRequested],
                    customKeyFieldName: 'id',
                    descriptionFieldName: 'descripcion',
                    customDescriptionFieldName: 'title',
                    orderByKeyValue: true,
                    useAscOrder: true
                }
            }
        }

        const apiServiceResultData = await apiProcessRequest({inputRequestData: requestData,
                                                             requestUrl: PARTIAL_PATH_GET_CATALOGS,
                                                             requestParamsData,
                                                             transformationResponseSettings});

        return {...apiServiceResultData};

    } catch (error) {
        const sourceReference = `<ApiMainService@getCatalogCollection>`;
        console.error(`${sourceReference} -----------`);
        console.error(`${sourceReference} DATA ERROR`);
        console.error(error);
        console.error(`${sourceReference} -----------`);
    }
}

export async function getSupportCasesSearchResults(requestSearchData) {

    try {

        const requestData = {...requestSearchData};

        const requestParamsData = { };

        const columnValuesOrderTemplate = { folio: "folio",
                                            fechaApertura: "fechaApertura",
                                            canal: "canal",
                                            estatus: "estatus",
                                            campana: "campana",
                                            tipificacion: "tipificacion",
                                            tipificacionDetalle: "tipificacionDetalle",
                                            __actions: "" }

        const transformationResponseSettings = {
            fuzzyTable: {
                params: {
                    columnValuesOrderTemplate: {...columnValuesOrderTemplate},
                    keyFieldName: '',
                    includeKeyField: false
                }
            }
        }

        const apiServiceResultData = await apiProcessRequest({inputRequestData: requestData,
                                                             requestUrl: '/folios',
                                                             requestParamsData,
                                                             transformationResponseSettings});
        console.log(requestData);
        console.log(apiServiceResultData)
        return apiServiceResultData;

    } catch (error) {
        const sourceReference = `<ApiMainService@getSupportCasesSearchResults>`;
        console.error(`${sourceReference} -----------`);
        console.error(`${sourceReference} DATA ERROR`);
        console.error(error);
        console.error(`${sourceReference} -----------`);
    }

    return null;
}

export async function registerNewInteraction(requestNewInteractionData) {

    try {

        const requestData = {...requestNewInteractionData};

        const requestParamsData = { };

        const transformationResponseSettings = {
        }

        const apiServiceResultData = await apiProcessRequest({inputRequestData: requestData,
                                                             requestUrl: PARTIAL_PATH_REGISTER_NEW_INTERACTION,
                                                             requestParamsData,
                                                             transformationResponseSettings});

        return apiServiceResultData;

    } catch (error) {
        const sourceReference = `<ApiMainService@registerNewInteraction>`;
        console.error(`${sourceReference} -----------`);
        console.error(`${sourceReference} DATA ERROR`);
        console.error(error);
        console.error(`${sourceReference} -----------`);
    }

    return null;
}

// -------------------------------------------------------------------------
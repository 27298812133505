import React from 'react';

var FuzzyTableContextCollection = {}
var FuzzyTableContextManager = {};

(function(GlobalFuzzyTableContextManager, GlobalFuzzyTableContextCollection) {
    'use strict';

    GlobalFuzzyTableContextManager.addContext = function (contextDescriptor) {
        //if (!GlobalFuzzyTableContextCollection.hasOwnProperty(contextDescriptor)) {
            //Object.defineProperty(GlobalFuzzyTableContextCollection, contextDescriptor, { value: React.createContext({}), writable: true});
            //GlobalFuzzyTableContextCollection.contextDescriptor = React.createContext({});
        //}

        GlobalFuzzyTableContextCollection.contextDescriptor = React.createContext({});

        return GlobalFuzzyTableContextCollection.contextDescriptor;
    };

    GlobalFuzzyTableContextManager.getContext = function(contextDescriptor) {
            if (GlobalFuzzyTableContextCollection.hasOwnProperty(contextDescriptor)) {
                return GlobalFuzzyTableContextCollection.contextDescriptor;
            }

            GlobalFuzzyTableContextCollection.contextDescriptor = React.createContext({});
            return GlobalFuzzyTableContextCollection.contextDescriptor;
        }
    ;

}.bind(this)(FuzzyTableContextManager, FuzzyTableContextCollection));

export default FuzzyTableContextManager;
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UserInfoValidationDialog from "./UserInfoValidationDialog";
import { makeStyles } from "@material-ui/core/styles";
import { grey, red } from "@material-ui/core/colors";
import axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  buttonPrimary: {
    color: "#FFFFFF",
    backgroundColor: "#FF995B",
  },
  buttonDanger: {
    backgroundColor: red[400],
    color: grey[50],
    "&:hover": {
      backgroundColor: red[400],
    },
  },
  buttonSuccess: {
    "font-weight": 700,
    color: "#66BB6A",
    padding: "10px 20px",
  },
});

const SupportCaseUserInfoValidationControl = ({
  userInfoContextValue,
  userInfoContextStateUpdateCallback,
  validationPopups,
}) => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_API_URL + "/support/console";
  const { supportCaseId } = useParams();

  const [dataValidacion, setDataValidacion] = useState([]);
  const [showUserInfoValidationDialog, setShowUserInfoValidationDialog] =
    useState(false);

  const handleUserInfoValidationOpenDialog = () => {
    setShowUserInfoValidationDialog(!showUserInfoValidationDialog);
  };

  async function obtenerDataValidacion() {
    try {
      const response = await axios({
        url:
          `${baseUrl}/usuario/info/verificacion/?pa_usuario=` + supportCaseId,
        method: "POST",
        params: {},
        timeout: 500000,
      });
      console.log(response);
      const dataResponse = response.data.response[0];
      console.log(dataResponse);
      setDataValidacion(dataResponse);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    obtenerDataValidacion();
  }, []);

  return (
    <>
      {userInfoContextValue.isUserInfoValidatedSuccessfully ? (
        <div className={classes.buttonSuccess} disabled={true}>
          <AssignmentTurnedInIcon style={{ marginRight: "8px" }} />
          <span>Información de usuario validada</span>
        </div>
      ) : (
        <Button
          variant="contained"
          className={classes.buttonDanger}
          startIcon={<LockIcon />}
          onClick={handleUserInfoValidationOpenDialog}
          disabled={false}
        >
          Validar información de usuario
        </Button>
      )}

      {showUserInfoValidationDialog && (
        <UserInfoValidationDialog
          displayDIalog={showUserInfoValidationDialog}
          userInfoUpdateContextCallback={userInfoContextStateUpdateCallback}
          parentToogleShowThis={setShowUserInfoValidationDialog}
          validationPopups={validationPopups}
          changeValidacion={{ dataValidacion, setDataValidacion }}
        />
      )}
    </>
  );
};

export default SupportCaseUserInfoValidationControl;

import Typography from "@material-ui/core/Typography";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import DescriptionIcon from "@material-ui/icons/Description";
import SingleEventinfoBlock from "./SingleEventInfoBlock";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bottomSpacer: {
    marginBottom: "10px",
  },
  spacerTime: {
    marginTop: "8px",
  },
}));

const SupportCaseTimelineEvent = ({
  color,
  creationTime,
  eventName,
  comments,
  eventCreatorName,
}) => {
  const classes = useStyles();

  return (
    <TimelineItem className={classes.bottomSpacer}>
      <TimelineOppositeContent>
        <Typography variant="subtitle1" className={classes.spacerTime}>
          {creationTime}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{ background: color === 0 ? "#66BB6A" : "" }}>
          <DescriptionIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <SingleEventinfoBlock
          eventName={eventName}
          comments={comments}
          eventCreatorName={eventCreatorName}
        />
      </TimelineContent>
    </TimelineItem>
  );
};

export default SupportCaseTimelineEvent;

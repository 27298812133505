import Hidden from '@material-ui/core/Hidden';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
    contentTextLikeH6: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        fontSize: "1.25rem",
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
        textAlign: "justify",
        marginBottom: "1em"
    },
    contentHighlightedTextLikeH6: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
        textAlign: "justify"
    },
    auxiliarBoldedText: {
        color: grey[500],
        fontWeight: "bold",
        paddingTop: "1em",
        marginBottom: "1em"
    },
    auxiliarBoldedTextGREEN: {
        color: "#66bb6a",
        fontWeight: "bold",
        paddingTop: "1em",
        marginBottom: "1em"
    },
    auxiliarBoldedTextORANGE: {
        color: "#ff9b5b",
        fontWeight: "bold",
        paddingTop: "1em",
        marginBottom: "1em"
    },
    auxiliarBoldedTextBLACK: {
        color: "#000",
        fontWeight: "bold",
        paddingTop: "1em",
        marginBottom: "1em"
    },
    bottomSpacer: {
        marginBottom: "2em"
    }

})

const StyledTextField = withStyles({
    root: {
        '& input:disabled': {
            color: grey[900]
        }
    },
})(TextField);

const UserInfoValidationContentPanel = ({contentType, fieldLabel, fieldValidationValue, currentIndexPanel, totalFIeldValidationListCount}) => {

    const classes = useStyles();

    const VALIDATION_FEEDBACK_TEXT = ["Necesitamos validar algunos datos del UPAXER para poder mostrar la información."]
    const VALIDATION_SUCCESSFULLY_MESSAGE = ["¡Excelente! La validación de datos del UPAXER ha concluido con éxito.",
                                             "A partir de ahora podrás visualizar la información relativa al caso sin restricciones."];
    const VALIDATION_FAILED_MESSAGE = ["¡Ups! Desafortunadamente la información del UPAXER no pudo ser confirmada, por lo que los datos relativos al caso seguirán ocultos por motivos de seguridad.",
                                       "De manera adicional, puedes acudir con tu encargado para solicitar apoyo ante este evento."];

    const buildMultilineInfoFragment = (arrayDataSource) => {
        return (
            <div>
                {
                    arrayDataSource.map((currentString, itemIndex) => {
                            if (currentString === null || currentString === "") {
                                return (
                                    <Hidden key={itemIndex} only="md" >
                                        <div className={classes.contentTextLikeH6}>.</div>
                                    </Hidden>
                                );
                            } else {
                                return (<Typography key={itemIndex} className={classes.contentTextLikeH6} gutterBottom>
                                            {currentString}
                                        </Typography>);
                            }
                    })
                }
           </div>
        )
    }

    const getFieldValidationFeedbackText = (fieldLabel) => {
        return (<div className={classes.contentTextLikeH6}>
            Por favor, confirme que el UPAXER le proporciona la información de
            <span className={classes.contentHighlightedTextLikeH6}> "{fieldLabel}" </span>-
            tal como se muestra a continuación:
        </div>);
    }

    const getValidationFeedbackText = (contentType) => {
        switch (contentType) {
            case "STATUS_ok":
                return buildMultilineInfoFragment(VALIDATION_SUCCESSFULLY_MESSAGE);
            case "STATUS_ERROR":
                return buildMultilineInfoFragment(VALIDATION_FAILED_MESSAGE);
            default:
                return buildMultilineInfoFragment(VALIDATION_FEEDBACK_TEXT);
        }
    }

    const getFieldValidationNumberFeedback = (currentIndexPanel, totalFIeldValidationListCount) => {
        return (<Typography variant="body1" className={classes.auxiliarBoldedText}>
                    {`Mostrando campo ${currentIndexPanel + 1} de ${totalFIeldValidationListCount} disponible(s)`}
                </Typography>)
    }
    const getFieldValidation = (fieldLabel,fieldValidationValue) => {
        return (<Typography variant="h5" className={classes.auxiliarBoldedTextORANGE} >
            {`${fieldLabel} : ${fieldValidationValue}`}
        </Typography>
        )
    }

    return (
        <>
            { getValidationFeedbackText(contentType) }
            {
                contentType === "FIELD" && ( getFieldValidationFeedbackText(fieldLabel) )
            }
            {
                contentType === "FIELD" && ( getFieldValidationNumberFeedback(currentIndexPanel, totalFIeldValidationListCount) )
            }
            {
                contentType === "FIELD" && ( getFieldValidation(fieldLabel,fieldValidationValue) )
            }
            {/*
                contentType === "FIELD" && (
                                            <StyledTextField InputProps={{ readOnly: true,
                                                                           startAdornment: (
                                                                               <InputAdornment position="start">
                                                                                   <QuestionAnswerIcon />
                                                                               </InputAdornment>
                                                                         )}}
                                                             disabled
                                                             type="text"
                                                             defaultValue={fieldValidationValue}
                                                             fullWidth
                                                             variant="outlined" />
                                          )*/
            }
        </>
    )
}

export default UserInfoValidationContentPanel;
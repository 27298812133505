import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { UserInfoValidationContext } from "../../SupportConsoleComponent/SupportCaseDetailsBlock";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";

const getItemIdString = (itemIndex) => {
  return `panel${itemIndex}-content`;
};
const useStyles = makeStyles({
  pageContent: {
    margin: "10px",
  },
  headButton: {
    marginLeft: "10px",
    position: "fixed",
  },
  headTitle: {
    marginLeft: "50px",
  },
  success: {
    backgroundColor: green[500],
  },
  fail: {
    backgroundColor: red[500],
  },
  auxiliarBoldedTexORANGE: {
    color: "#ff995b",
    fontWeight: "bold",
    paddingTop: "1em",
    marginBottom: "1em",
    flexGrow: 1,
    align: "center",
  },
});
const ComposedAccordionItem = ({ itemIndex, title, details }) => {
  const classes = useStyles();
  return (
    <>
      <UserInfoValidationContext.Consumer>
        {({ userInfoValidationState, updateValidationProcessState }) => (
          <Accordion
            disabled={
              userInfoValidationState.isUserInfoValidatedSuccessfully
                ? false
                : true
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={getItemIdString(itemIndex)}
              id={getItemIdString(itemIndex)}
            >
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    className={classes.auxiliarBoldedTexORANGE}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {details}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </UserInfoValidationContext.Consumer>
    </>
  );
};

export default ComposedAccordionItem;

import React, { Suspense, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

import Tittles from "../components/controls/searchInteractionControls/Titles.jsx";
import SearchSupportCasesForm from "../components/SearchInteractionComponent/SearchSupportCasesForm";
import NewInteractionForm from "../components/SearchInteractionComponent/NewInteractionForm";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import theme from "../../themeConfig";

import IconButton from "@material-ui/core/IconButton";
import { Provider as FetchProvider } from "use-http";
import ControlLoader from "../components/ControlLoader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import DataTable from "react-data-table-component";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DataTableExtensions from "react-data-table-component-extensions";
import CancelIcon from "@material-ui/icons/Cancel";

import "../assets/css/DataTable.css";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: "3em",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  auxiliarBoldedTexORANGE: {
    color: "#FFFFFF",
    fontWeight: "normal",
    //paddingTop: "1em",
    //marginBottom: "1em",
    margin: ".5em",
    flexGrow: 0.1,
    fontSize: 16,
    align: "center",
  },
  customWidth: {
    maxWidth: 500,
  },
}));
//try to set the font-size here
export default function InteractionDashboard() {
  const siteNavigationHistory = useHistory();
  const classes = useStyles();

  const { userId } = useParams();

  const columns = [
    {
      name: "Folio",
      selector: "folio",
      sortable: true,
      center: true,
    },
    {
      name: "Fecha apertura",
      selector: "fecha_creacion",
      sortable: true,
      center: true,
    },
    {
      name: "Estatus",
      selector: "estatus",
      sortable: true,
      center: true,
    },
    {
      name: "Nivel",
      selector: "nivel",
      sortable: true,
      center: true,
    },
    {
      name: "Tipificación",
      selector: "tipificacion",
      sortable: true,
      center: true,
      fontWeight: 14,
    },
    {
      name: "Observación",
      selector: "ultimo_seguimiento",
      sortable: true,
      left: true,
      minWidth: "200px",
      cell: (row) => (
        <Tooltip
          title={
            <Typography
              variant="h6"
              className={classes.auxiliarBoldedTexORANGE}
            >
              {row.ultimo_seguimiento}
            </Typography>
          }
          placement="left"
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <span
            data-tip={row.ultimo_seguimiento}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {truncate(row.ultimo_seguimiento)}
          </span>
        </Tooltip>
      ),
    },
    {
      cell: (row) => (
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => handleAction(row.folio, row.estatus)}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const agregarComentario = async (folio, comentario) => {
    try {
      const response = await axios({
        url: `${baseUrl}/incidencia/comentarios/registro/`,
        data: {
          pa_incidencia: folio,
          pa_comentarios: comentario,
          idusrmodifico: atob(userId),
          paid_comentario: 5,
        },
        method: "POST",
        params: {},
        timeout: 500000,
      });
      console.log(response);
      siteNavigationHistory.push(`/console/${userId}/case/${folio}`);
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  const truncate = (str) => {
    if (str != null) {
      return str.length > 30 ? str.substring(0, 25) + "..." : str;
    } else {
      return "";
    }
  };
  const handleAction = (id, estatus) => {
    if (estatus === "Abierto") {
      agregarComentario(id, "Visualizar caso");
    } else {
      siteNavigationHistory.push(`/console/${userId}/case/${id}`);
    }
  };

  const [showInteractionForm, setShowInteractionForm] = useState(false);
  const [showSearchCasesTable, setShowSearchCasesTable] = useState(false);
  const [searchCasesInProgress, setSearchCasesInProgress] = useState(false);
  const [lastSearchCasesResultCode, setLastSearchCasesResultCode] =
    useState(-1);
  const [supportCasesResults, setSupportCasesResults] = useState([]);
  const [searchFormHasErrors, setSearchFormHasErrors] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [nivelUsuario, setNivelUsuario] = useState(-1);
  // COMMENT Add useFetch-Provider to fetch select options in child components

  ///https://adminsls.upaxdev.com

  const baseUrl = process.env.REACT_APP_API_URL + "/support/console";

  const consultarNivelUsuario = async () => {
    try {
      const response = await axios({
        url: `${baseUrl}/usuario/sesion/nivel/?pausuario=` + atob(userId),
        method: "POST",
        params: {},
        timeout: 500000,
      });
      console.log(response);
      setNivelUsuario(parseInt(response.data.response["nivel"]));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    consultarNivelUsuario();
  }, []);

  const baseUrl2 = process.env.REACT_APP_API_URL;

  console.log("new", showInteractionForm);

  return (
    <>
      <ThemeProvider theme={theme}>
        {buscando && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <FetchProvider
          url={baseUrl2}
          options={{
            cachePolicy: "no-cache",
            retries: 3,
            retryDelay: 1000,
            timeout: 60000,
            onAbort: () => {
              console.log(
                "<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> Aborting the current request by user..."
              );
            },
            onError: ({ error }) => {
              console.error(
                `<SearchSupportCasesForm@GlobalConfigurationObject#onError> The request could not be completed and the last retry was wasted... [Details: ${error}`
              );
            },
            onTimeOut: () => {
              console.log(
                "<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> The max-timeout was reached, the request will be cancelled..."
              );
            },
            responseType: "json",
            suspense: true,
          }}
        >
          <Suspense fallback={<ControlLoader />}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              className={classes.grid}
            >
              <Grid item>
                <Tittles />
              </Grid>
              <Grid item>
                <SearchSupportCasesForm
                  supportCasesResultState={{
                    showSearchCasesTable,
                    setShowSearchCasesTable,
                    supportCasesResults,
                    setSupportCasesResults,
                  }}
                  supportCasesSearchState={{
                    searchCasesInProgress,
                    setSearchCasesInProgress,
                    lastSearchCasesResultCode,
                    setLastSearchCasesResultCode,
                  }}
                  searchFormValidationState={{
                    searchFormHasErrors,
                    setSearchFormHasErrors,
                  }}
                  interactionFormState={{
                    showInteractionForm,
                    setShowInteractionForm,
                  }}
                  changeCliente={{ clientes, setClientes }}
                  changeBuscando={{ buscando, setBuscando }}
                  userId
                  changeNivel={{ nivelUsuario, setNivelUsuario }}
                />
              </Grid>

              {!searchFormHasErrors && showSearchCasesTable && (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={2}
                  component={Paper}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={classes.grid}
                    item
                    xs={12}
                  >
                    <Grid item xs={10}>
                      <Suspense fallback={<ControlLoader />}>
                        <DataTableExtensions
                          columns={columns}
                          data={clientes}
                          print={false}
                          export={false}
                          filter={clientes.length !== 0}
                        >
                          <DataTable
                            className="dataTables_wrapper"
                            noDataComponent={<NoDataComponent />}
                            pagination
                          />
                        </DataTableExtensions>
                      </Suspense>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {showInteractionForm && (
                <Grid item>
                  <NewInteractionForm
                    currentUserId={userId}
                    changeNivel={{ nivelUsuario, setNivelUsuario }}
                  />
                </Grid>
              )}
            </Grid>
          </Suspense>
        </FetchProvider>
      </ThemeProvider>
    </>
  );
}

const NoDataComponent = () => {
  return (
    <div className="no-data-component">
      <CancelIcon />
      <span>No se han encontrado coincidencias con su búsqueda</span>
    </div>
  );
};

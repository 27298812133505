import React, { Suspense, useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../themeConfig";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// "Titles" is used as a header component
import Titles from "../components/controls/searchInteractionControls/Titles";
import UserInfoBlock from "../components/SupportConsoleComponent/UserInfoBlock";
import SupportCaseDetailsBlock from "../components/SupportConsoleComponent/SupportCaseDetailsBlock";
import SupportCaseActionBar from "../components/SupportConsoleComponent/SupportCaseActionBar";
import SupportCaseTimelineBlock from "../components/SupportConsoleComponent/SupportCaseTimelineBlock";
import FuzzyTableContextManager from "../components/FuzzyTable/FuzzyTableContextManager";
import * as apiMainService from "../services/apiMainService";
import { Provider as FetchProvider } from "use-http/dist/cjs/Provider";
import ControlLoader from "../components/ControlLoader";
import axios from "axios";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const supportConsoleContext = "TaskAndPayments";
const TaskPaymentsFuzzyTableContext = FuzzyTableContextManager.addContext(
  supportConsoleContext
);

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: "3em",
  },
  messageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  message: {
    height: "56px",
    padding: "19px 16px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: "#ffffff",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
  },
  messageSuccess: {
    background: "#66BB6A",
  },
  messageError: {
    background: "#D33604",
  },
}));

const SupportCaseDetailsView = () => {
  //const baseurl = "http://dev.upaxer.first-level-support.s3-website-us-east-1.amazonaws.com";
  const classes = useStyles();
  const { userId } = useParams();
  const { supportCaseId } = useParams();
  const [actualizarInfoBlock, setActualizarInfoBlock] = useState(false);
  const [actualizarTimeline, setActualizarTimeline] = useState(false);
  const [actualizarInformacionAdicional, setActualizarIformacionAdicional] =
    useState(false);
  const [isMessageActive, setIsMessageActive] = useState(false);
  const [message, setMessage] = useState("");
  const [isMessageSuccess, setIsMessageSuccess] = useState(true);

  const [estadoCaso, setEstadoCaso] = useState(false);
  const messageRef = useRef();

  const baseUrl2 = process.env.REACT_APP_API_URL;
  return (
    <>
      <ThemeProvider theme={theme}>
        <FetchProvider
          url={baseUrl2}
          options={{
            cachePolicy: "no-cache",
            retries: 3,
            retryDelay: 1000,
            timeout: 60000,
            onAbort: () => {
              console.log(
                "<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> Aborting the current request by user..."
              );
            },
            onError: ({ error }) => {
              console.error(
                `<SearchSupportCasesForm@GlobalConfigurationObject#onError> The request could not be completed and the last retry was wasted... [Details: ${error}`
              );
            },
            onTimeOut: () => {
              console.log(
                "<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> The max-timeout was reached, the request will be cancelled..."
              );
            },
            responseType: "json",
            suspense: true,
          }}
        >
          <Suspense fallback={<ControlLoader />}>
            <Grid
              container
              direction="column"
              className={classes.grid}
              spacing={4}
            >
              <Grid item>
                <Titles />
              </Grid>
              <Grid item>
                <UserInfoBlock
                  changeActualizarInfoBlock={{
                    actualizarInfoBlock,
                    setActualizarInfoBlock,
                  }}
                />
              </Grid>
              {isMessageActive && (
                <div className={classes.messageContainer} ref={messageRef}>
                  {isMessageSuccess ? (
                    <div
                      className={classes.message + " " + classes.messageSuccess}
                    >
                      {" "}
                      <CheckCircleIcon style={{ marginRight: "10px" }} />{" "}
                      {message}{" "}
                    </div>
                  ) : (
                    <div
                      className={classes.message + " " + classes.messageError}
                    >
                      {" "}
                      <CancelIcon style={{ marginRight: "10px" }} /> {message}{" "}
                    </div>
                  )}
                </div>
              )}
              <Grid item>
                <SupportCaseTimelineBlock
                  supportCaseId={supportCaseId}
                  changeActualizarTimeline={{
                    actualizarTimeline,
                    setActualizarTimeline,
                  }}
                />
              </Grid>
              <Grid item>
                <SupportCaseDetailsBlock
                  LocalFuzzyTableContext={TaskPaymentsFuzzyTableContext}
                  validationPopups={{}}
                  userId={userId}
                  changeActualizarIformacionAdicional={{
                    actualizarInformacionAdicional,
                    setActualizarIformacionAdicional,
                  }}
                  //const [estadoCaso, setEstadoCaso] = useState(false);
                  changeEstadoCaso={{ estadoCaso, setEstadoCaso }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              spacing={0}
              className={classes.grid}
            >
              <Grid item>
                <SupportCaseActionBar
                  changeActualizarInfoBlock={{
                    actualizarInfoBlock,
                    setActualizarInfoBlock,
                  }}
                  changeActualizarTimeline={{
                    actualizarTimeline,
                    setActualizarTimeline,
                  }}
                  changeActualizarIformacionAdicional={{
                    actualizarInformacionAdicional,
                    setActualizarIformacionAdicional,
                  }}
                  changeEstadoCaso={{ estadoCaso, setEstadoCaso }}
                  messageActions={{
                    setMessage,
                    setIsMessageActive,
                    setIsMessageSuccess,
                  }}
                  messageRef={messageRef}
                />
              </Grid>
            </Grid>
          </Suspense>
        </FetchProvider>
      </ThemeProvider>
    </>
  );
};

export default SupportCaseDetailsView;

import FuzzyTable from "../../FuzzyTable/FuzzyTable";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getTaskPaymentDetails } from "../../../services";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DataTableExtensions from "react-data-table-component-extensions";
//import 'react-data-table-component-extensions/dist/index.css';
import "../../../assets/css/DataTable.css";

const customStyles = {
  rows: {
    style: {
      minHeight: "53px",
      color: "#9B9B99",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
};

const TaskPaymentDetails = ({ LocalFuzzyTableContext }) => {
  const useStyles = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(1),
      border: "1px solid #dadde9",
    },
    auxiliarBoldedTexORANGE: {
      color: "#FFFFFF",
      fontWeight: "normal",
      //paddingTop: "1em",
      //marginBottom: "1em",
      margin: ".5em",
      flexGrow: 0.1,
      fontSize: 16,
      align: "center",
    },
    customWidth: {
      maxWidth: 500,
    },
    grid: {
      padding: "3em",
    },
  }));

  const classes = useStyles();

  const [data, setData] = useState([]);

  const columns = [
    {
      name: "Id_Asignación",
      selector: "id_asignacion",
      sortable: true,
      center: true,
    },
    {
      name: "Proyecto",
      selector: "proyecto",
      sortable: true,
      wrap: true,
      center: true,
      minWidth: "200px",
    },
    {
      name: "Ubicación",
      selector: "ubicacion",
      sortable: true,
      wrap: true,
      center: true,
    },
    {
      name: "Tarea",
      selector: "nombre_tarea",
      sortable: true,
      center: true,
      minWidth: "200px",
      cell: (row) => (
        <Tooltip
          title={
            <Typography
              variant="h6"
              className={classes.auxiliarBoldedTexORANGE}
            >
              {row.nombre_tarea}
            </Typography>
          }
          placement="left"
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <span
            data-tip={row.nombre_tarea}
            style={{
              maxHeight: "3rem",
              lineHeight: "1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.nombre_tarea}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "Estatus campo",
      selector: "estatus_campo",
      sortable: true,
      center: true,
      minWidth: "200px",
      cell: (row) => (
        <Tooltip
          title={
            <Typography
              variant="h6"
              className={classes.auxiliarBoldedTexORANGE}
            >
              {row.estatus_campo}
            </Typography>
          }
          placement="left"
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <span
            data-tip={row.estatus_campo}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {truncate(row.estatus_campo)}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "Fecha campo",
      selector: "fecha_campo",
      sortable: true,
      center: true,
    },
    {
      name: "Estatus auditoría",
      selector: "estatus_auditoria",
      sortable: true,
      left: true,
      //wrap: true,
      minWidth: "200px",
      cell: (row) => (
        <Tooltip
          title={
            <Typography
              variant="h6"
              className={classes.auxiliarBoldedTexORANGE}
            >
              {row.estatus_auditoria}
            </Typography>
          }
          placement="left"
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <span
            data-tip={row.estatus_auditoria}
            customStyles
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {truncate(row.estatus_auditoria)}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "Fecha auditoria",
      selector: "fecha_confirmacion",
      sortable: true,
      center: true,
    },
    {
      name: "Estatus pago",
      selector: "estatus_pago",
      sortable: true,
      center: true,
      wrap: true,
      cell: (row) => (
        <Tooltip
          title={
            <Typography
              variant="h6"
              className={classes.auxiliarBoldedTexORANGE}
            >
              {row.estatus_pago}
            </Typography>
          }
          placement="left"
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <span
            data-tip={row.estatus_pago}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {truncate(row.estatus_pago)}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "Fecha pago",
      selector: "fecha_de_pago",
      sortable: true,
      center: true,
    },
    {
      name: "Fecha envió a pago",
      selector: "fecha_envio_pago",
      sortable: true,
      center: true,
    },
    {
      name: "Monto",
      selector: "monto_pago",
      sortable: true,
      center: true,
    },
    {
      name: "Comentario calidad",
      selector: "comentarios",
      sortable: true,
      minWidth: "200px",
      cell: (row) => (
        <Tooltip
          title={
            <Typography
              variant="h6"
              className={classes.auxiliarBoldedTexORANGE}
            >
              {row.comentarios}
            </Typography>
          }
          placement="left"
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <span
            data-tip={row.comentarios}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {truncate(row.comentarios)}
          </span>
        </Tooltip>
      ),
    },
  ];

  const truncate = (str) => {
    if (str != null) {
      return str.length > 30 ? str.substring(0, 25) + "..." : str;
    } else {
      return "";
    }
  };

  const { supportCaseId } = useParams();

  const baseUrl = process.env.REACT_APP_API_URL + "/support/console";

  const getTareasPagos = async (supportCaseId) => {
    try {
      const response = await axios({
        url: baseUrl + `/usuario/info/tareas/?pafolio=` + supportCaseId,
        method: "POST",
      });
      setData(response.data.response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTareasPagos(supportCaseId);
  }, []);
  const tableData = {
    columns,
    data,
  };

  return (
    <Grid container direction="column" justify="center" component={Paper}>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        <Grid item xs={12} style={{ width: "1100px" }}>
          <DataTableExtensions {...tableData} print={false} export={false}>
            <DataTable
              className="dataTables_wrapper"
              noDataComponent="No hay tareas y pagos para mostrar"
              pagination
              print
              responsive={true}
              customStyles={customStyles}
            />
          </DataTableExtensions>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskPaymentDetails;

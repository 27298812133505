import PanelDynamicListContent from "./PanelDynamicListContent";
import { useEffect, useState } from "react";
import useFetch from "use-http";
import { getDataAdicional } from "../../../services/index";
import { useParams } from "react-router-dom";

const phoneFormat = (phone) =>
  `${phone.slice(0, 2)}-${phone.slice(2, 6)}-${phone.slice(6)}`;

const MoreInfoDetails = ({
  userId,
  changeActualizarIformacionAdicional,
  changeEstadoCaso,
}) => {
  const [dataAdicional, setDataAdicional] = useState([]);

  const sampleDataAdicional = {
    fields: [
      {
        label: "Número de caso (folio):",
        value: "",
      },
      {
        label: "Origen:",
        value: "",
      },
      {
        label: "Estado:",
        value: "",
      },
      {
        label: "Teléfono:",
        value: "",
      },
      {
        label: "Prioridad:",
        value: "",
      },
      {
        label: "Nivel:",
        value: "",
      },
      {
        label: "Fecha de creación:",
        value: "",
      },
      {
        label: "Ultima modificación:",
        value: "",
      },
      {
        label: "Creado por:",
        value: "",
      },
      {
        label: "Usuario Modifico:",
        value: "",
      },
      {
        label: "País:",
        value: "",
      },
      {
        label: "",
        value: "",
      },
    ],
  };
  const baseUrl = process.env.REACT_APP_API_URL;
  const { get, post, loading, response, error } = useFetch(baseUrl, {
    cachePolicy: "no-cache",
    retries: 3,
    retryDelay: 1000,
    timeout: 60000,
    onAbort: () => {
      console.log(
        "<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> Aborting the current request by user..."
      );
    },
    onError: ({ error }) => {
      console.error(
        `<SearchSupportCasesForm@GlobalConfigurationObject#onError> The request could not be completed and the last retry was wasted... [Details: ${error}`
      );
    },
    onTimeOut: () => {
      console.log(
        "<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> The max-timeout was reached, the request will be cancelled..."
      );
    },
    responseType: "json",
    suspense: true,
  });
  const { supportCaseId } = useParams();
  useEffect(() => {
    //changeActualizarIformacionAdicional
    console.log(
      "IA ===> " +
        changeActualizarIformacionAdicional.actualizarInformacionAdicional
    );
    if (
      changeActualizarIformacionAdicional.actualizarInformacionAdicional == true
    ) {
      console.log("creo que se actualizara el componente IA");
      changeActualizarIformacionAdicional.setActualizarIformacionAdicional(
        false
      );
    }
    async function loadDataAdicional() {
      const response = await getDataAdicional(supportCaseId);
      if (response != error && response.status == 200) {
        console.log("estatus 200");
        if (response.data.response.length > 0) {
          console.log("mayor a 0");
          sampleDataAdicional["fields"][0]["value"] =
            response.data.response[0]["folio"];
          sampleDataAdicional["fields"][1]["value"] =
            response.data.response[0]["medio"];
          sampleDataAdicional["fields"][2]["value"] =
            response.data.response[0]["estatus"];
          sampleDataAdicional["fields"][3]["value"] = phoneFormat(
            response.data.response[0]["medio_contacto"]
          );
          sampleDataAdicional["fields"][4]["value"] =
            response.data.response[0]["prioridad"];
          sampleDataAdicional["fields"][5]["value"] =
            response.data.response[0]["nivel"];
          sampleDataAdicional["fields"][6]["value"] =
            response.data.response[0]["fecha_creacion"];
          sampleDataAdicional["fields"][7]["value"] =
            response.data.response[0]["fecha_actualiza"];
          sampleDataAdicional["fields"][8]["value"] =
            response.data.response[0]["usr_crea"];
          sampleDataAdicional["fields"][9]["value"] =
            response.data.response[0]["usr_actualiza"];
          sampleDataAdicional["fields"][10]["value"] =
            response.data.response[0]["pais"];
          if (
            response.data.response[0]["estatus"].localeCompare("Abierto") === 0
          ) {
            changeEstadoCaso.setEstadoCaso(true);
          } else {
            changeEstadoCaso.setEstadoCaso(false);
          }
          setDataAdicional(sampleDataAdicional);
          return response;
        } else {
          console.log("no es mayor de 0");
        }
      }
    }
    loadDataAdicional();
  }, [changeActualizarIformacionAdicional.actualizarInformacionAdicional]);

  return <PanelDynamicListContent objectDataSource={dataAdicional} />;
};

export default MoreInfoDetails;

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { green, red } from "@material-ui/core/colors";
import { GRID_COLUMN_HEADER_SEPARATOR_RESIZABLE_CSS_CLASS } from "@material-ui/data-grid";

const useStyles = makeStyles({
  rightSpacer: {
    marginRight: "10px",
  },
  dialogTitle: {
    fontWeight: "bold",
  },
  primaryColor: {
    color: "#FF995B",
  },
  errorColor: {
    color: red[400],
    marginRight: "8px",
  },
  successColor: {
    color: green[400],
    marginRight: "8px",
  },
});

const GetIconForTitleType = ({ titleType }) => {
  const classes = useStyles();
  switch (titleType) {
    case "STATUS_OK":
      return <CheckIcon className={classes.successColor} />;
    case "STATUS_ERROR":
      return <CancelIcon className={classes.errorColor} />;
    default:
      return null;
  }
};

const getTextForTitleType = (titleType) => {
  switch (titleType) {
    case "STATUS_OK":
      return "Validación exitosa de datos";
    case "STATUS_ERROR":
      return "Validación de datos con problemas";
    default:
      return "Validación de datos";
  }
};

const getCustomClassNameForTitleType = (titleType) => {
  switch (titleType) {
    case "STATUS_OK":
      return "successColor";
    case "STATUS_ERROR":
      return "errorColor";
    default:
      return "primaryColor";
  }
};

const UserInfoValidationDialogTItle = ({ titleType }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item alignItems="center" justify="center">
          <GetIconForTitleType titleType={titleType} />
        </Grid>
        <Grid item>
          <Typography
            className={
              (classes.dialogTitle,
              classes[getCustomClassNameForTitleType(titleType)])
            }
            variant="h4"
          >
            {getTextForTitleType(titleType)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInfoValidationDialogTItle;

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useParams } from "react-router-dom";
import { Dialog, DialogContentText, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey, red } from "@material-ui/core/colors";
import Controls from "../controls/searchInteractionControls/Controls";
import { SupportConsoleForm } from "./SupportConsoleForm";

import axios from "axios";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CommentIcon from "@material-ui/icons/Comment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import rightArrowIcon from "./rightArrowIcon.svg";

import useFetch from "use-http";

const niveles = [
  { id: 1, title: "Administración UPAXER" },
  { id: 2, title: "Negocio UPAXER" },
  { id: 3, title: "Negocio encuestas" },
  { id: 4, title: "Negocio Mystery Shopper" },
  { id: 5, title: "Calidad" },
  { id: 6, title: "Segundo Nivel" },
  { id: 7, title: "Desarrollo" },
];

const escalamientos = [
  [2, 3, 4, 5, 6],
  [3, 4, 5, 6],
  [2, 4, 5, 6],
  [2, 5, 6],
  [2, 6],
  [2, 7],
  [2, 3, 4, 5, 6],
];

const useStyles = makeStyles({
  rightSpacer: {
    marginRight: "2em",
  },
  bottomSpacer: {
    marginBottom: "2em",
  },
  dialogTitle: {
    fontWeight: "bold",
  },
  buttonError: {
    color: grey[50],
    backgroundColor: red[400],
  },
  buttonSuccess: {
    color: grey[50],
    backgroundColor: "#66BB6A",
  },
  followupButton: {
    height: "40px",
    fontWeight: "700",
    backgroundColor: "#FF995B",
    border: "none",
    color: "#FFFFFF",
    padding: "11px 16px",
    "&:hover": {
      backgroundColor: "#FF7C1B",
    },
  },

  upgradeCaseButton: {
    height: "40px",
    fontWeight: "700",
    backgroundColor: "#FF995B",
    border: "none",
    color: "#FFFFFF",
    padding: "11px 16px",
    "&:hover": {
      backgroundColor: "#FF7C1B",
    },
  },
  closeCaseButton: {
    height: "40px",
    fontWeight: "700",
    backgroundColor: "#ffffff",
    border: "1px solid #FF995B",
    color: "#FF995B",
    padding: "11px 16px",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#FF7C1B",
    },
  },
  auxiliarBoldedTexRED: {
    color: "#FF0000",
    fontWeight: "bold",
    paddingTop: "1em",
    marginBottom: "1em",
    fontSize: "14px",
  },
  cancelButton: {
    fontWeight: "400",
    textDecorationLine: "underline",
    fontSize: "14px",
    lineHeight: "16px",
    border: "none",
    background: "inherit",
    color: "#FF995B",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  modalTitle: {
    margin: "10px 0 8px 0",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
  },
  modalContent: {
    fontSize: "14px",
    lineHeight: "156%",
    color: "#9B9B99",
  },
  dialog: {
    padding: "30px",
  },
  dialogContainerFollowCase: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    width: "390px",
    height: "380px",
    padding: "15px",
    fontSize: "14px",
  },
  dialogContainerUpgradeCase: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-around",
    width: "380px",
    height: "480px",
    padding: "15px",
    fontSize: "14px",
  },
  dialogContainerCloseCase: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    width: "380px",
    height: "410px",
    padding: "15px",
    fontSize: "14px",
  },
  textFieldContainer: {
    margin: "8px 0 0 0",
    width: "100%",
  },
  textAreaError: {
    margin: "5px",
    color: "red",
    fontSize: "12px",
    fontWeight: "400",
  },
  insideCloseCaseButton: {
    padding: "11px 16px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "16px",
    border: "none",
    background: "#D33604",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D33604",
    },
  },
  insideUpgradeCaseButton: {
    padding: "11px 16px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "16px",
    border: "none",
    background: "#FF995B",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF995B",
    },
  },
  insideFollowCaseButton: {
    padding: "11px 16px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "16px",
    border: "none",
    background: "#66BB6A",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#66BB6A",
    },
  },
  selector: {
    width: "18px",
    fontSize: "12px",
  },
});

const SupportCaseActionBar = ({
  changeActualizarInfoBlock,
  changeActualizarTimeline,
  changeActualizarIformacionAdicional,
  changeEstadoCaso,
  messageActions,
  messageRef,
}) => {
  console.log("estadoCaso", changeEstadoCaso.estadoCaso);
  const classes = useStyles();
  const fieldsInitialState = {
    id: 0,
    escalamiento: "",
    comentario: "",
  };

  const isFormValidHandler = (fieldValues = values) => {
    let temp = { ...errors };
    if ("escalamiento" in fieldValues)
      temp.escalamiento = fieldValues.escalamiento
        ? ""
        : "Este campo es requerido";
    if ("comentario" in fieldValues) {
      if (fieldValues.comentario.length === 0) {
        temp.comentario = "Este campo es requerido";
      } else if (fieldValues.comentario.length < 20) {
        temp.comentario = "Se requieren más de 20 caracteres";
      } else {
        temp.comentario = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    SupportConsoleForm(fieldsInitialState, true, isFormValidHandler);
  const classess = useStyles();

  const [openEscalar, setOpenEscalar] = React.useState(false);
  const [openSeguimiento, setOpenSeguimiento] = React.useState(false);
  const [openCerrar, setOpenCerrar] = React.useState(false);
  const { userId } = useParams();
  const { supportCaseId } = useParams();
  const [pausuario, setPausuario] = React.useState("");
  const [casoEscalado, setCasoEscalado] = React.useState(false);
  const [texto, setTexto] = React.useState("");
  //variables de estado para mostrar boton
  const [mostrarBotonEscalar, setMostrarBotonEscalar] = React.useState(false);
  const [mostrarBotonComentario, setMostrarBotonComentario] =
    React.useState(false);
  const [mostrarBotonCerrar, setMostrarBotonCerrar] = React.useState(false);
  const [mostrarBotonSalir, setMostrarBotonSalir] = React.useState(true);
  //
  const [validacionBotones, setValidacionBotones] = React.useState(true);
  const siteNavigationHistory = useHistory();

  const handleClickOpenEscalar = () => {
    setOpenEscalar(true);
  };

  const handleClickSeguimiento = () => {
    setOpenSeguimiento(true);
  };

  const handleClickCerrar = () => {
    setOpenCerrar(true);
  };
  const handleClickSalir = () => {
    siteNavigationHistory.push(`/interaction/dashboard/${userId}`);
  };

  const baseUrl = process.env.REACT_APP_API_URL + "/support/console";

  const sendEmail = async () => {
    const receive_email = "alopez.bts@gmail.com";
    const message = "Escalamiento de caso";
    const subject = "Escalamiento de caso";
    const sender_email = "alopez.bts@gmail.com";
    try {
      const response = await axios({
        url:
          `${baseUrl}/sendemail/?receive_email=` +
          receive_email +
          `&message=` +
          message +
          `&subject=` +
          subject +
          `&sender_email=` +
          sender_email,
        method: "POST",
        params: {},
        timeout: 500000,
      });
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  const agregarComentario = async (paid_comentario, comentario) => {
    try {
      const response = await axios({
        url: `${baseUrl}/incidencia/comentarios/registro/`,
        data: {
          pa_incidencia: supportCaseId,
          pa_comentarios: comentario,
          idusrmodifico: atob(userId),
          paid_comentario: paid_comentario,
        },
        method: "POST",
        params: {},
        timeout: 500000,
      });
      changeActualizarTimeline.setActualizarTimeline(true);
      handleClose();
      setTexto("");
      return response;
    } catch (e) {
      console.log(e);
      //await succes("Error!","Sucedió un error al registrar los comentarios","danger","top-right");
      messageActions.setMessage(
        "¡Error! Sucedió un error al registrar los comentarios"
      );
      messageActions.setIsMessageSuccess(false);
      messageActions.setIsMessageActive(true);
      handleClose();
      setTexto("");
    }
  };

  const seguimiento = async () => {
    if (values.comentario.length > 19 && 1000 > values.comentario.length) {
      const response = agregarComentario(2, values.comentario);
      if (response) {
        messageActions.setMessage("¡Éxito! Comentario agregado correctamente.");
        messageActions.setIsMessageSuccess(true);
        messageActions.setIsMessageActive(true);
        messageRef.current.scrollIntoView();
      }
    } else {
      if (values.comentario.length < 20) {
        setTexto("Debes de ingresar un comentario de al menos 20 caracteres.");
      } else {
        setTexto("Máximo 1000 caracteres.");
      }
    }
  };

  const escalar = async () => {
    if (
      values.comentario.length > 19 &&
      1000 > values.comentario.length &&
      isFormValidHandler(values)
    ) {
      console.log("entra");
      try {
        const response = await axios({
          url: `${baseUrl}/incidencia/actualiza/nivel/`,
          data: {
            pa_incidencia: supportCaseId,
            paid_nivel: values.escalamiento,
            idusrmodifico: atob(userId),
          },
          method: "POST",
          params: {},
          timeout: 500000,
        });
        changeActualizarIformacionAdicional.setActualizarIformacionAdicional(
          true
        );
        changeActualizarInfoBlock.setActualizarInfoBlock(true);
        messageActions.setMessage("¡Éxito! Caso escalado correctamente.");
        messageActions.setIsMessageSuccess(true);
        messageActions.setIsMessageActive(true);

        agregarComentario(3, values.comentario);
        sendEmail();
        handleClose();
        setTexto("");
        setValidacionBotones(true);
        console.log("VB ===> " + validacionBotones);
        setCasoEscalado(true);
      } catch (e) {
        console.log(e);
        messageActions.setMessage(
          "¡Error! Sucedió un error al escalar el caso"
        );
        messageActions.setIsMessageSuccess(false);
        messageActions.setIsMessageActive(true);

        handleClose();
        setTexto("");
      }
    } else {
      if (values.comentario.length < 20) {
        setTexto("Debes de ingresar un comentario de al menos 20 caracteres.");
      } else {
        setTexto("Máximo 1000 caracteres.");
      }
    }
  };

  const cerrarCaso = async () => {
    if (values.comentario.length > 19 && 1000 > values.comentario.length) {
      console.log("entra");
      try {
        const response = await axios({
          url:
            `${baseUrl}/incidencia/actualiza/estatus/?pa_incidencia=` +
            supportCaseId +
            `&paid_estatus=2&idusrmodifico=` +
            atob(userId),
          method: "POST",
          params: {},
          timeout: 500000,
        });
        changeActualizarIformacionAdicional.setActualizarIformacionAdicional(
          true
        );
        //changeActualizarTimeline.setActualizarTimeline(true);
        messageActions.setMessage("Éxito! Caso cerrado correctamente.");
        messageActions.setIsMessageSuccess(true);
        messageActions.setIsMessageActive(true);
        changeEstadoCaso.setEstadoCaso(false);

        agregarComentario(4, values.comentario + " ...CASO CERRADO...");
        handleClose();
        setTexto("");
        //setValidacionBotones(true);
        //siteNavigationHistory.push(`/interaction/dashboard/${userId}`);
      } catch (e) {
        console.log(e);
        messageActions.setMessage("Error! Sucedió un error al cerrar el caso");
        messageActions.setIsMessageSuccess(false);
        messageActions.setIsMessageActive(true);
        handleClose();
        setTexto("");
      }
    } else {
      if (values.comentario.length < 20) {
        setTexto("Debes de ingresar un comentario de al menos 20 caracteres.");
      } else {
        setTexto("Máximo 1000 caracteres.");
      }
    }
    setValidacionBotones(true);
  };

  const handleClose = () => {
    setOpenEscalar(false);
    setOpenSeguimiento(false);
    setOpenCerrar(false);
    setValues({ ...values, comentario: "" });
    setErrors({ ...errors, comentario: "" });
    setTexto("");
  };

  const consultarBotones = async () => {
    //http://127.0.0.1:5000/adm/support/console/validacion/nivel/?pafolio=2303211&pausuario=23720
    try {
      const response = await axios.post(
        "https://fmu963vr12.execute-api.us-east-1.amazonaws.com/dev/perfiles/get",
        { userId: atob(userId) }
      );
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  const validarBotones = async () => {
    const response = await consultarBotones();
    const buttons = response.data?.body.data.map((it) => it["FIIDBOTON"]);
    console.log("respuesta", buttons);
    const perfil = response.data?.body.data[0]["FIIDPERFIL"];

    if (buttons && changeEstadoCaso.estadoCaso) {
      buttons.map((id) => {
        if (id === 1) {
          setMostrarBotonComentario(true);
        } else if (id === 2) {
          setOptionListNivel(
            escalamientos[perfil - 1]
              .map((it) => niveles.filter((niv) => niv.id === it))
              .flat()
          );
          setMostrarBotonEscalar(true);
        } else if (id === 3) {
          setMostrarBotonCerrar(true);
        }
      });
    } else {
      setMostrarBotonComentario(false);
      setMostrarBotonEscalar(false);
      setMostrarBotonCerrar(false);
    }
  };

  const [optionListNivel, setOptionListNivel] = React.useState();

  useEffect(() => {
    if (validacionBotones == true) {
      setValidacionBotones(false);
    }
    validarBotones();
  }, [changeEstadoCaso.estadoCaso]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={4}
    >
      {/* DIALOGO DEL SEGUIMIENTO ##################################################################################3 */}
      {mostrarBotonComentario && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickSeguimiento}
            className={classess.followupButton}
          >
            Seguimiento
            <img src={rightArrowIcon} style={{ marginLeft: "11px" }} />
          </Button>

          <Dialog
            open={openSeguimiento}
            onClose={handleClose}
            aria-labelledby="form-dialog-seguimiento"
          >
            <div className={classess.dialogContainerFollowCase}>
              <div className={classess.modalTitle}>Seguimiento del caso</div>

              <DialogContentText>
                <span className={classes.modalContent}>
                  Por favor introduzca una descripción del seguimiento del caso:
                </span>
              </DialogContentText>
              <TextField
                name="comentario"
                id="comentario"
                label="Escribe aquí tu comentario"
                inputProps={{
                  style: { fontSize: "12px", height: "118px", padding: "0px" },
                }}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                multiline
                rows={10}
                value={values.comentario}
                fullWidth={1}
                variant="outlined"
                error={errors.comentario}
                onChange={handleInputChange}
              />
              <div className={classes.textAreaError}>{texto}</div>
              <div className={classess.actionButtons}>
                <Button
                  onClick={seguimiento}
                  className={classess.insideFollowCaseButton}
                >
                  Seguimiento
                  <CommentIcon style={{ marginLeft: "11px" }} />
                </Button>
                <button
                  onClick={handleClose}
                  variant="contained"
                  /* color="primary" */
                  className={classess.cancelButton}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </Dialog>
        </Grid>
      )}
      {/* DIALOGO DEL ESCALAMIENTO ##################################################################################3 */}
      {mostrarBotonEscalar && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpenEscalar}
            className={classess.upgradeCaseButton}
          >
            Escalamiento
            <AssignmentIndIcon style={{ marginLeft: "11px" }} />
          </Button>

          <Dialog
            open={openEscalar}
            onClose={handleClose}
            aria-labelledby="form-dialog-escalar"
            sx={{ background: "red" }}
          >
            <div className={classess.dialogContainerUpgradeCase}>
              <div className={classess.modalTitle}>Escalar caso</div>
              <span className={classes.modalContent}>
                Por favor seleccione a quién se le escalará el caso
              </span>
              <Controls.Select
                name="escalamiento"
                label="Selecciona a quién escalar"
                MenuProps={{ style: { fontSize: "12px" } }}
                value={values.escalamiento}
                onChange={handleInputChange}
                options={optionListNivel}
                error={errors.escalamiento}
                classes={classess.selector}
              />
              <div style={{ height: "15px" }} />
              <span className={classes.modalContent}>
                Por favor introduzca una descripción del escalamiento del caso:
              </span>
              <div className={classess.textFieldContainer}>
                <TextField
                  name="comentario"
                  id="comentario"
                  label="Escribe aquí los comentarios del escalamiento."
                  inputProps={{
                    style: {
                      fontSize: "12px",
                      height: "118px",
                      padding: "0px",
                    },
                  }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  multiline
                  rows={7}
                  value={values.comentario}
                  fullWidth={1}
                  variant="outlined"
                  error={errors.comentario}
                  onChange={handleInputChange}
                  classes={{ fontSize: "14px" }}
                />
                <div className={classes.textAreaError}>{texto}</div>
              </div>
              <div className={classess.actionButtons}>
                <Button
                  onClick={escalar}
                  className={classess.insideUpgradeCaseButton}
                >
                  Escalar caso
                  <AssignmentIndIcon style={{ marginLeft: "11px" }} />
                </Button>
                <button onClick={handleClose} className={classess.cancelButton}>
                  Cancelar
                </button>
              </div>
            </div>
          </Dialog>
        </Grid>
      )}
      {/* DIALOGO DE CERRAR CASO ##################################################################################3 */}
      {mostrarBotonCerrar && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCerrar}
            className={classess.closeCaseButton}
          >
            Cerrar caso
            <ExitToAppIcon style={{ marginLeft: "11px" }} />
          </Button>

          <Dialog
            open={openCerrar}
            onClose={handleClose}
            aria-labelledby="form-dialog-cerrar"
          >
            <div className={classes.dialogContainerCloseCase}>
              <div className={classess.modalTitle}>Cerrar caso</div>
              <div className={classess.modalContent}>
                Para continuar, agrega un comentario a la incidencia y enseguida
                da clic en el botón "Cerrar caso" de lo contrario, da clic en
                "Cancelar"
              </div>
              <div className={classes.textFieldContainer}>
                <TextField
                  name="comentario"
                  id="comentario"
                  label="Escribe aquí tu comentario"
                  inputProps={{
                    style: {
                      fontSize: "12px",
                      height: "118px",
                      padding: "0px",
                    },
                  }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  multiline
                  rows={4}
                  value={values.comentario}
                  fullWidth={1}
                  variant="outlined"
                  error={errors.comentario}
                  onChange={handleInputChange}
                />
                <div className={classes.textAreaError}>{texto || ""}</div>
              </div>
              <div style={{ color: "red" }}>
                ¿Estás seguro de cerrar el caso?
              </div>
              <div className={classess.actionButtons}>
                <Button
                  onClick={cerrarCaso}
                  className={classess.insideCloseCaseButton}
                >
                  Cerrar caso
                  <ExitToAppIcon style={{ marginLeft: "11px" }} />
                </Button>
                <button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className={classess.cancelButton}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </Dialog>
        </Grid>
      )}
      <Grid item>
        {(() => {
          if (mostrarBotonSalir === true || casoEscalado === true) {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickSalir}
                className={classess.upgradeCaseButton}
              >
                Salir
                <ExitToAppIcon style={{ marginLeft: "11px" }} />
              </Button>
            );
          }
        })()}
      </Grid>
    </Grid>
  );
};

export default SupportCaseActionBar;

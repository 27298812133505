import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import "../../../assets/css/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  headTable: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "20px",
    paddingTop: "20px",
  },
});

function Tittle(props) {
  const classes = useStyles();

  return (
    <div className={classes.headTable}>
      {/* <FontAwesomeIcon
        style={{ fontSize: "2em", color: "#FF995B", justifyContent: "center" }}
      /> */}
      <Typography
        style={{ marginLeft: "20px", color: "#FF995B", fontWeight: "700", fontSize: "32px", fontHeight: "37px"}}
        variant="h5"
        gutterBottom
      >
        Crear incidencia
      </Typography>
    </div>
  );
}

export default Tittle;

import { useEffect, useState } from "react";
import { getData } from "../../../services/index";
import { useParams } from "react-router-dom";
import "./UserInfoDetails.css";

const formatoClabe = (cl) =>
  `${cl.slice(0, 3)}  ${cl.slice(3, 6)}  ${cl.slice(6, 17)}  ${cl.slice(17)}`;

const UserInfoDetails = ({ userId }) => {
  const { supportCaseId } = useParams();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function loadData() {
      try {
        const res = await (await getData(supportCaseId)).data.response;

        if (res.length > 0) {
          let passUser = {
            clabe: res[0]["clabe"] || "Sin información",
            cuenta_deposito: res[0]["cuenta_deposito"] || "Sin información",
            cuenta_paypal: res[0]["cuenta_paypal"] || "Sin información",
            curp: res[0]["curp"] || "Sin información",
            id_usuario: res[0]["id_usuario"] || "Sin información",
            nombre_banco: res[0]["nombre_banco"] || "Sin información",
            numero_tarjeta: res[0]["numero_tarjeta"] || "Sin información",
            rfc: res[0]["rfc"] || "Sin información",
            tipopago: res[0]["tipopago"] || "Sin información",
          };

          setUserData(passUser);
          return res;
        }
      } catch (e) {
        console.log(e);
      }
    }
    loadData();
  }, []);

  if (!userData) {
    return <div></div>;
  } else {
    return (
      <div className="tableContainer">
        <ul>
          <li>
            <label> CLABE:</label> {formatoClabe(userData["clabe"])}
          </li>
          <li>
            <label> Cuenta de Paypal:</label> {userData["id_usuario"]}
          </li>
          <li>
            <label> ID usuario:</label> {userData["id_usuario"]}
          </li>
          <li>
            <label> Número de tarjeta:</label> {userData["numero_tarjeta"]}
          </li>
          <li>
            <label> Tipo de pago:</label> {userData["tipopago"]}
          </li>
        </ul>
        <ul>
          <li>
            <label> Cuenta de depósito:</label> {userData["cuenta_deposito"]}
          </li>
          <li>
            <label> CURP:</label> {userData["curp"]}
          </li>
          <li>
            <label> Nombre de Banco:</label> {userData["nombre_banco"]}
          </li>
          <li>
            <label> RFC:</label> {userData["rfc"]}
          </li>
        </ul>
      </div>
    );
  }
};

export default UserInfoDetails;

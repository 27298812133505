import React, { Suspense, useState, useMemo, useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import Controls from "../controls/searchInteractionControls/Controls";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  Form,
  SearchForm,
} from "../controls/searchInteractionControls/SearchForm";
import * as interactionService from "../../services/interactionService_Original";
import * as apiMainService from "../../services/apiMainService";
import useFetch from "use-http";
import {
  getSesionData,
  getSearchListaFolios,
  getTaskPaymentDetails,
  getSearchFolio,
} from "../../services/index";
import ControlLoader from "../ControlLoader";
import axios from "axios";

const SearchSupportCasesForm = ({
  supportCasesResultState,
  supportCasesSearchState,
  searchFormValidationState,
  interactionFormState,
  apiCatalogCollection,
  changeCliente,
  changeBuscando,
  userId,
  changeNivel,
}) => {
  // COMMENT **************************************************
  //              useFetch configuration and objects loading
  //         **************************************************

  // COMMENT -------------> Component's state objects

  const [optionListStatus, setOptionListStatus] = useState([]);
  const [optionListTyping, setOptionListTyping] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [searchType, setSearchType] = useState(0);
  // COMMENT -------------> useFetch configuration
  const baseUrl = process.env.REACT_APP_API_URL + "/support/console";
  const { get, post, loading, response, error } = useFetch();

  const getSearchCollection = () => [
    { id: "0", title: "Folio" },
    { id: "1", title: "Pax" },
    { id: "2", title: "Fecha" },
  ];

  const loadOptionListStatus = async () => {
    //aqui se cargaran los datos al combobox
    const retrievedOptions = await post(
      `support/console/catalogos/?tipo=estatus`
    );
    console.log("---loadOptionListStatus----", retrievedOptions);
    if (response.ok && retrievedOptions != error) {
      setOptionListStatus(
        retrievedOptions?.response.map((el, index) => {
          return Object.assign(
            {},
            { id: el.idestatus || index + 1, title: el.estatus }
          );
        })
      );
    }
  };
  const loadOptionListTyping = async () => {
    //aqui se cargaran los datos al combobox
    const retrievedOptions = await post(
      `support/console/catalogos/?tipo=tipificacion`
    );

    if (response.ok && retrievedOptions != error) {
      setOptionListTyping(
        retrievedOptions?.response.map((el) => {
          return Object.assign(
            {},
            { id: el.tipificacion, title: el.descripcion }
          );
        })
      );
    }
  };

  useEffect(() => {
    if (changeNivel.nivelUsuario === -1) {
      setShowButton(true);
      console.log("ocultar botones");
    } else {
      setShowButton(true);
    }

    loadOptionListStatus();
    loadOptionListTyping();
    //console.log(process.env.REACT_APP_API_URL + '/support/console');
  }, [changeNivel.nivelUsuario]);

  // COMMENT Other component's configurations

  const fieldsInitialState = {
    id: 0,
    search: 0,
    folio: "",
    paxStaff: "PAX",
    initialDate: new Date(),
    finalDate: new Date(),
    status: "",
    typing: "",
  };
  const handleSearchChange = (selectedOption) => {
    let temp = { ...errors };
    setErrors({});
    setSearchType(selectedOption.target.value);
    console.log(selectedOption.target.value);
  };
  const isFormValidHandler = (fieldValues = values) => {
    let temp = { ...errors };

    if ("folio" in fieldValues) {
      if (searchType == 0) {
        temp.folio = fieldValues.folio ? "" : "Este campo es requerido";
        temp.folio =
          fieldValues.folio.length >= 5 ? "" : "Se requieren 5 dígitos";
      }
    }
    if ("paxStaff" in fieldValues) {
      if (searchType == 1) {
        temp.paxStaff = fieldValues.paxStaff ? "" : "Este campo es requerido";
        temp.paxStaff =
          fieldValues.paxStaff.length >= 5
            ? ""
            : "Se requieren mínimo 5 caracteres";
      }
    }
    return { ...temp };

    /*    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      const isFormValidState = Object.values(temp).every((x) => x === "");
      return isFormValidState;
    } */
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    SearchForm(fieldsInitialState, true, isFormValidHandler);

  const isSearchButtonDisabled = useMemo(() => {
    console.log("SEARCH BUTTON", errors, searchType);
    return !(
      (Object.values(errors).every((error) => error === "") &&
        Object.keys(errors).length !== 0) ||
      searchType == 2
    );
  }, [errors]);

  const resetSearchCasesState = () => {
    supportCasesResultState.setShowSearchCasesTable(false);
    supportCasesResultState.setSupportCasesResults([]);
    supportCasesSearchState.setSearchCasesInProgress(false);
    supportCasesSearchState.setLastSearchCasesResultCode(-1);
    searchFormValidationState.setSearchFormHasErrors(false);
    resetForm();
  };

  const searchOnClickHandler = () => {
    //este metodo se ejecuta cuando el susuario le da clic al boton buscar
    supportCasesSearchState.setSearchCasesInProgress(true);
    supportCasesResultState.setShowSearchCasesTable(true);

    interactionFormState.setShowInteractionForm(false);
  };
  const getFecha = (fecha) => {
    //obtener datos formulario
    let dateYear = fecha.getFullYear();
    let dateMonth = fecha.getMonth() + 1;
    let dateDay = fecha.getDate();
    if (dateDay < 10) {
      dateDay = "0" + dateDay;
    }
    if (dateMonth < 10) {
      dateMonth = "0" + dateMonth;
    }

    return dateDay + "/" + dateMonth + "/" + dateYear;
  };
  const newInteractionOnClickHandler = () => {
    interactionFormState.setShowInteractionForm(true);
    resetSearchCasesState();
  };

  const loadTable = async () => {
    const res = await post("folios");
    if (response.ok) {
      const data = await response.json();
      console.log(data.response);
      changeCliente.setClientes(data.response);
    }
  };
  const loadListaFolios = async () => {
    const response = await getSearchListaFolios();
    if (response.status === 200) {
      const data = response.data;
      changeCliente.setClientes(data);
      /*
            setNombre(response.data.response[0]['nombre']);*/
      return response;
    }
  };
  const Buscarporfolio = async (pa_folio) => {
    ///hacer var true
    changeBuscando.setBuscando(true);
    const res = await post(
      "support/console/folios/busqueda/individual/?pa_folio=" + pa_folio
    );
    if (response.ok) {
      const data = await response.json();
      changeCliente.setClientes(data.response);
    }
    //hacer var false
    changeBuscando.setBuscando(false);
  };
  const Buscarporusuario = async (pa_user) => {
    changeBuscando.setBuscando(true);
    const res = await post(
      "support/console/folios/busqueda/usuario/?pa_usuario=" + pa_user
    );
    if (response.ok) {
      const data = await response.json();
      changeCliente.setClientes(data.response);
    }
    changeBuscando.setBuscando(false);
  };
  const Buscarporfecha = async (
    fechainicio,
    fechafin,
    estatus,
    tipificacion
  ) => {
    /*
        try{
            changeBuscando.setBuscando(true);
            const response = await axios({
                url: baseUrl+'/folios/busqueda/fecha/?fechainicio='+fechainicio+'&fechafin='+fechafin+'&estatus='+estatus+'&tipificacion='+tipificacion,
                method: 'POST',
                params: {},
                timeout: 500000
            });
            const data =  response.data.response;
            changeCliente.setClientes(data.response);
            return response
            changeBuscando.setBuscando(false);
        }catch (e) {
            console.log(e);
            changeBuscando.setBuscando(false);
        }
        changeBuscando.setBuscando(false);*/

    changeBuscando.setBuscando(true);
    const res = await post(
      "support/console/folios/busqueda/fecha/?fechainicio=" +
        fechainicio +
        "&fechafin=" +
        fechafin +
        "&estatus=" +
        estatus +
        "&tipificacion=" +
        tipificacion
    );
    if (response.ok) {
      const data = await response.json();
      changeCliente.setClientes(data.response);
    }
    changeBuscando.setBuscando(false);
  };
  const agregarComentario = async (
    fechainicio,
    fechafin,
    estatus,
    tipificacion
  ) => {
    ///folios/busqueda/fecha/?fechainicio='+fechainicio+'&fechafin='+fechafin+'&estatus='+estatus+'&tipificacion='+tipificacion);
    //
    try {
      changeBuscando.setBuscando(true);
      const response = await axios({
        url: `${baseUrl}/folios/busqueda/fecha/?fechainicio='+fechainicio+'&fechafin='+fechafin+'&estatus='+estatus+'&tipificacion='+tipificacion`,
        method: "POST",
        params: {},
        timeout: 500000,
      });
      const data = response.data.response;
      changeCliente.setClientes(data.response);
      return response;
    } catch (e) {
      console.log(e);
    }
    changeBuscando.setBuscando(false);
  };

  const handleSearchSupportCases = (filterSearchValues) => {
    if (searchType == 0) {
      //Por folio
      Buscarporfolio(values.folio);
    } else if (searchType == 1) {
      //Por por pax
      Buscarporusuario(values.paxStaff);
    } else if (searchType == 2) {
      //Por fecha  23:47:54
      let fechainicial = getFecha(values.initialDate) + " 01:00:00";
      let fechafinal = getFecha(values.finalDate) + " 23:59:59";
      let estatus = values.status;
      let tipificacion = values.typing;

      if (estatus == "") {
        estatus = 0;
      }
      if (tipificacion == "") {
        tipificacion = 0;
      }
      Buscarporfecha(fechainicial, fechafinal, estatus, tipificacion);
    } else {
      //sin filtro
      loadListaFolios();
    }
  };

  const formOnSubmitHandler = (e) => {
    e.preventDefault();
    if (isFormValidHandler(values)) {
      searchFormValidationState.setSearchFormHasErrors(false);
      handleSearchSupportCases(values);
    } else {
      supportCasesSearchState.setSearchCasesInProgress(false);
      searchFormValidationState.setSearchFormHasErrors(true);
    }
  };

  return (
    <>
      <Form onSubmit={formOnSubmitHandler}>
        <Grid
          container
          style={{
            paddingTop: "5px",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Grid item xs={2}>
            <Controls.Select
              name="search"
              label="Selecciona el tipo de busqueda"
              value={searchType}
              onChange={handleSearchChange}
              options={getSearchCollection()}
              error={errors.search}
            />
          </Grid>

          {(() => {
            if (searchType == 0) {
              return (
                <Grid item xs={2}>
                  <Controls.Inputs
                    name="folio"
                    label="Escribe aquí el folio"
                    value={values.folio}
                    onChange={handleInputChange}
                    error={errors.folio}
                    inputProps={{ maxLength: 10 }}
                    onKeyPress={(ev) => {
                      if (!/[0-9]/.test(ev.key)) ev.preventDefault();
                    }}
                  />
                </Grid>
              );
            } else if (searchType == 1) {
              return (
                <Grid item xs={2}>
                  <Controls.Inputs
                    name="paxStaff"
                    label="Escribe aquí el PAX "
                    value={values.paxStaff}
                    onChange={handleInputChange}
                    error={errors.paxStaff}
                    inputProps={{ maxLength: 10 }}
                    onKeyPress={(ev) => {
                      if (!/[A-Za-z0-9]/.test(ev.key)) ev.preventDefault();
                    }}
                  />
                </Grid>
              );
            } else {
              return [
                <Grid key={"fechainicial"} item xs={2}>
                  <Controls.DatePicker
                    name="initialDate"
                    label="Introduce Fecha Inicial"
                    value={values.initialDate}
                    onChange={handleInputChange}
                  />
                </Grid>,
                <Grid key={"fechafinal"} item xs={2}>
                  <Controls.DatePicker
                    name="finalDate"
                    label="Introduce Fecha Final"
                    value={values.finalDate}
                    onChange={handleInputChange}
                  />
                </Grid>,
                <Grid key={"estatus"} item xs={2}>
                  <Controls.Select
                    name="status"
                    label="Selecciona estatus"
                    value={values.status}
                    onChange={handleInputChange}
                    options={optionListStatus}
                    error={errors.status}
                  />
                </Grid>,
                <Grid key={"tipificacion"} item xs={2}>
                  <Controls.Select
                    name="typing"
                    label="Selecciona tipificación"
                    value={values.typing}
                    onChange={handleInputChange}
                    options={optionListTyping}
                    error={errors.typing}
                  />
                </Grid>,
              ];
            }
          })()}
        </Grid>
        <br />
        <br />
        <div style={{ alignItems: "center", textAlign: "center" }}>
          {showButton && (
            <Controls.Buttons
              type="submit"
              text="Buscar"
              startIcon={<SearchIcon />}
              disabled={isSearchButtonDisabled}
              onClick={searchOnClickHandler}
            />
          )}
          {showButton && (
            <Controls.Buttons
              text="Crear incidencia"
              startIcon={<DescriptionIcon />}
              onClick={newInteractionOnClickHandler}
            />
          )}
        </div>
        <br />
        <br />
      </Form>
    </>
  );
};

export default SearchSupportCasesForm;

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    ...theme.typography.subtitle1,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    padding: "10px",
  },
  info: {
    ...theme.typography.subtitle1,
  },
}));

const UserInfoDetailRow = ({ label, value }) => {
  const classes = useStyles();
  //Grid item container direction="row" justify="center" alignItems="center"
  return (
    <Grid
      container
      item
      xs={6}
      spacing={2}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={6}>
        <div className={classes.field}>
          {label} <span className={classes.info}>{value}</span>
        </div>
      </Grid>
    </Grid>
  );
};

const PanelDynamicListContent = ({ objectDataSource }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item container direction="row" justify="center" alignItems="center">
        {objectDataSource?.fields?.map((item, stepIndex) => {
          return (
            <UserInfoDetailRow
              key={stepIndex}
              label={item.label}
              value={item.value}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default PanelDynamicListContent;

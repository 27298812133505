import React from "react";
import {Box, Grid} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as Logo} from "../../../assets/css/images/LogoUpaxer.svg";
import '../../../assets/css/App.css'

const useStyles = makeStyles((theme) => ({
    headTable:{
        display:'flex',
        marginLeft: '10px',
    },
    logo: {

        height: 80,
        ['@media (min-width: 250px)']: { // eslint-disable-line no-useless-computed-key
            width: '70px',
        },
        ['@media (min-width: 280px)']: { // eslint-disable-line no-useless-computed-key
            width: '70px',
        },
        ['@media (min-width: 320px)']: { // eslint-disable-line no-useless-computed-key
            width: '90px',
        },
        ['@media (min-width: 360px)']: { // eslint-disable-line no-useless-computed-key
            width: '100px',
        },
        ['@media (min-width: 411px)']: { // eslint-disable-line no-useless-computed-key
            width: '120px',
        },
    },
    h1: {

        alignSelf: 'center',
        textAlign: 'center',
        color: '#FF995B'
    },
}));


function Titles(props){
    const classes = useStyles();

    return(
        <div className={classes.headTable}>
            <Grid container>
                <Grid item xs={4}>
                    <Box>
                        <Logo className={classes.logo}/>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        {/*<h2 style={{textAlign: 'center', color: '#FF995B'}}>Consola de Soporte</h2>*/}
                        <h1 className={classes.h1}>Consola de Soporte</h1>
                    </Box>
                </Grid>

            </Grid>
        </div>
    )
}

export default Titles;
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SupportCaseUserInfoValidationControl from "./SupportCaseUserInfoValidationControl";
import {
  getUserInfoPanel,
  getTaskAndPaymentsPanel,
  getMoreInfoPanel,
} from "../controls/SupportConsoleControls/AccordionCaseDetailsPanelFactory";
import { makeStyles } from "@material-ui/core/styles";

const UserInfoValidationContext = React.createContext({
  isUserInfoValidatedSuccessfully: true,
  failedTriesCounter: 0,
});
const useStyles = makeStyles({
  pageContent: {
    margin: "10px",
  },
  headButton: {
    marginLeft: "10px",
    position: "fixed",
  },
  headTitle: {
    marginLeft: "50px",
  },
  auxiliarBoldedTexORANGE: {
    flexGrow: 1,
    color: "#ff995b",
    fontWeight: "400",
    fontSize: "1.5rem",
  },
  headingMenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    padding: "10px",
    margin: "20px",
  },
});
const SupportCaseDetailsBlock = ({
  LocalFuzzyTableContext,
  validationPopups,
  userId,
  changeActualizarIformacionAdicional,
  changeEstadoCaso,
}) => {
  const [isUserInfoValidatedSuccessfully, setIsUserInfoValidatedSuccessfully] =
    useState(false);
  const [failedTriesCounter, setFailedTriesCounter] = useState(0);

  const updateValidationProcessState = (isUserInfoValidatedSuccessfully) => {
    if (isUserInfoValidatedSuccessfully) {
      setIsUserInfoValidatedSuccessfully(true);
    } else {
      setIsUserInfoValidatedSuccessfully(false);
    }

    setFailedTriesCounter(failedTriesCounter + 1);
  };

  const supporCaseDetailsLocalState = {
    userInfoValidationState: {
      isUserInfoValidatedSuccessfully: isUserInfoValidatedSuccessfully,
      failedTriesCounter: failedTriesCounter,
    },
    updateValidationProcessState: updateValidationProcessState,
    validationPopups: validationPopups,
  };
  const classes = useStyles();
  return (
    <>
      <UserInfoValidationContext.Provider value={supporCaseDetailsLocalState}>
        <div className={classes.headingMenu}>
          <div className={classes.auxiliarBoldedTexORANGE}>
            Detalle del caso
          </div>

          <UserInfoValidationContext.Consumer>
            {({
              userInfoValidationState,
              updateValidationProcessState,
              validationPopups,
            }) => (
              <SupportCaseUserInfoValidationControl
                userInfoContextValue={userInfoValidationState}
                userInfoContextStateUpdateCallback={
                  updateValidationProcessState
                }
                validationPopups={validationPopups}
              />
            )}
          </UserInfoValidationContext.Consumer>
        </div>
        <Grid item xs>
          <div>
            {getUserInfoPanel(0, "Información del UPAXER", userId)}
            {getTaskAndPaymentsPanel(
              1,
              "Información de tareas y pagos",
              LocalFuzzyTableContext
            )}
            {getMoreInfoPanel(
              2,
              "Información adicional",
              userId,
              changeActualizarIformacionAdicional,
              changeEstadoCaso
            )}
          </div>
        </Grid>
      </UserInfoValidationContext.Provider>
    </>
  );
};

export default SupportCaseDetailsBlock;

export { UserInfoValidationContext };

import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    disabled,
  } = props;

  return (
    <FormControl
      style={{  paddingTop: "5px" }}
      fullWidth
      variant="outlined"
      disabled={disabled}
      {...(error && { error: true })}
    >
      <InputLabel style={{ paddingBottom: "-15px", width: "80%" }}>
        {label}
      </InputLabel>
      <MuiSelect label={label} name={name} value={value} onChange={onChange} style={{ borderRadius: "8px"}} >
        {options?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

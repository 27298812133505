import axios from "axios";

import municipios from "../../listaMunicipiosArreglo.json";

const baseUrl = process.env.REACT_APP_API_URL + "/support/console";

export async function getSearchFolio(pa_folio) {
  try {
    const response = await axios({
      url: `${baseUrl}/folios/busqueda/individual/`,
      data: {
        pa_folio: pa_folio,
      },
      method: "POST",
      params: {},
      timeout: 500000,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getSearchFecha(
  fechainicio,
  fechafin,
  estatus,
  tipificacion
) {
  try {
    const response = await axios({
      url: `${baseUrl}/folios/busqueda/fecha/`,
      data: {
        fechainicio: fechainicio,
        fechafin: fechafin,
        estatus: estatus,
        tipificacion: tipificacion,
      },
      method: "POST",
      params: {},
      timeout: 500000,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getSearchListaFolios() {
  try {
    const response = await axios({
      url: `${baseUrl}/console/folios`,
      method: "POST",
      params: {},
      timeout: 500000,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getSearchFoliosUsuario() {
  try {
    const response = await axios({
      url: `${baseUrl}/incidencia/actualiza/estatus/`,
      data: {
        pa_incidencia: "03052125",
        paid_estatus: 2,
        pausr_modifico: "PAXPRUEBA",
      },
      method: "POST",
      params: {},
      timeout: 500000,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getCerrarcaso(supportCaseId, idUser) {
  try {
    const response = await axios({
      url: `${baseUrl}/incidencia/actualiza/estatus/`,
      data: {
        pa_incidencia: supportCaseId,
        paid_estatus: 2,
        idusrmodifico: idUser,
      },
      method: "POST",
      params: {},
      timeout: 500000,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getCambioStatuss(supportCaseId, idUser) {
  try {
    const response = await axios({
      url:
        `http://127.0.0.1:5000/adm/support/console/incidencia/actualiza/estatus/?pa_incidencia=` +
        supportCaseId +
        `&paid_estatus=2&idusrmodifico=` +
        atob(idUser),
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getAgregarComentario(supportCaseId, comentario, idUser) {
  try {
    const response = await axios({
      url: `${baseUrl}/incidencia/comentarios/registro/`,
      data: {
        pa_incidencia: supportCaseId,
        pa_comentarios: comentario,
        idusrmodifico: atob(idUser),
        paid_comentario: 1,
      },
      method: "POST",
      params: {},
      timeout: 500000,
    });
    console.log("getAgregarComentario", response);
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getescalarCaso(supportCaseId, nivel, idUser) {
  try {
    const response = await axios({
      url: `${baseUrl}/incidencia/actualiza/nivel/`,
      data: {
        pa_incidencia: supportCaseId,
        paid_nivel: nivel,
        idusrmodifico: idUser,
      },
      method: "POST",
      params: {},
      timeout: 500000,
    });
    console.log(response);
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getSesionData() {
  const folio = "198";
  try {
    const response = await axios({
      url: `${baseUrl}/usuario/info/usersesion/?paid_usuario=198`,
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getGeneralData(folio) {
  try {
    const response = await axios({
      url: `${baseUrl}/usuario/info/general/?folio=` + folio,
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getSupportCaseHistory(supportCaseId) {
  const idincidencia = 1;
  try {
    const response = await axios({
      url: `${baseUrl}/incidencia/comentarios/?idincidencia=` + supportCaseId,
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getData(supportCaseId) {
  try {
    const response = await axios({
      url: `${baseUrl}/usuario/info/sensible/?pafolio=` + supportCaseId,
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getDataAdicional(supportCaseId) {
  try {
    const response = await axios({
      url: `${baseUrl}/usuario/info/adicional/?pafolio=` + supportCaseId,
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getTaskPaymentDetails(supportCaseId) {
  try {
    const response = await axios({
      url: `${baseUrl}/usuario/info/tareas/?pafolio=` + supportCaseId,
      method: "POST",
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getValidarExistenciaUsuario(paxStaff) {
  try {
    const response = await axios({
      url: `${baseUrl}/validacion/usuario/?pausuario=${paxStaff}`,
      method: "POST",
      params: {},
      timeout: 500000,
    });
    console.log("getValidarEsistenciaUsuario", response);
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getAbrirCaso({
  user,
  userId,
  system,
  platform,
  contactMedium,
  phone,
  typing,
  typingCause,
  nivelUsuario,
  country,
  location,
}) {
  console.log("Abrir caso args", {
    user,
    userId,
    system,
    platform,
    contactMedium,
    phone,
    typing,
    typingCause,
    nivelUsuario,
    country,
    location,
  });
  return axios({
    url: `${baseUrl}/incidencia/registro`,
    method: "POST",
    params: {},
    data: {
      paUsuario: user,
      paEstatus: 1,
      paidSistema: system,
      paidPlataforma: platform,
      pafiidMediocontacto: contactMedium,
      paMediocontacto: phone,
      paidTipificacion: typing,
      paidCausatipificacion: typingCause,
      paidPrioridad: 1,
      paidNivel: nivelUsuario,
      paidPais: country,
      paidPoblacion: location,
      pausrModifico: userId,
    },
    timeout: 500000,
  })
    .then((res) => {
      console.log("getAbrirCaso", res);
      const idNuevaIncidencia = res.data.response.idNuevoElemento;
      return idNuevaIncidencia;
    })
    .catch((err) => {
      console.log("PROBLEMA", err);
    });
}

//################ CATALOGOS

export const platform = [
  { title: "Web", id: 1 },
  { title: "Android", id: 2 },
  { title: "Ninguno", id: 3 },
];

export const country = [
  { title: "México", id: 1 },
  { title: "Estados Unidos", id: 2 },
];

export const statesMexico = [
  { title: "Aguascalientes", id: 1 },
  { title: "Baja California", id: 2 },
  { title: "Baja California Sur ", id: 3 },
  { title: "Campeche", id: 4 },
  { title: "Coahuila", id: 5 },
  { title: "Colima", id: 6 },
  { title: "Chiapas", id: 7 },
  { title: "Chihuahua", id: 8 },
  { title: "CDMX", id: 9 },
  { title: "Durango", id: 10 },
  { title: "Guanajuato", id: 11 },
  { title: "Guerrero", id: 12 },
  { title: "Hidalgo", id: 13 },
  { title: "Jalisco", id: 14 },
  { title: "Estado de México", id: 15 },
  { title: "Michoacán", id: 16 },
  { title: "Morelos", id: 17 },
  { title: "Nayarit", id: 18 },
  { title: "Nuevo León", id: 19 },
  { title: "Oaxaca", id: 20 },
  { title: "Puebla", id: 21 },
  { title: "Querétaro", id: 22 },
  { title: "Quintana Roo", id: 23 },
  { title: "San Luis Potosí", id: 24 },
  { title: "Sinaloa", id: 25 },
  { title: "Sonora", id: 26 },
  { title: "Tabasco", id: 27 },
  { title: "Tamaulipas", id: 28 },
  { title: "Tlaxcala", id: 29 },
  { title: "Veracruz", id: 30 },
  { title: "Yucatán", id: 31 },
  { title: "Zacatecas", id: 32 },
];

export const statesEU = [
  { title: "Alabama", id: 1 },
  { title: "Alaska", id: 2 },
  { title: "Arizona", id: 3 },
  { title: "Arkansas", id: 4 },
  { title: "California", id: 5 },
  { title: "Colorado", id: 6 },
  { title: "Connecticut", id: 7 },
  { title: "Delaware", id: 8 },
  { title: "Florida", id: 9 },
  { title: "Georgia", id: 10 },
  { title: "Hawaii", id: 11 },
  { title: "Idaho", id: 12 },
  { title: "Illinois", id: 13 },
  { title: "Indiana", id: 14 },
  { title: "Iowa", id: 15 },
  { title: "Kansas", id: 16 },
  { title: "Kentucky", id: 17 },
  { title: "Louisiana", id: 18 },
  { title: "Maine", id: 19 },
  { title: "Maryland", id: 20 },
  { title: "Massachusetts", id: 21 },
  { title: "Michigan", id: 22 },
  { title: "Minnesota", id: 23 },
  { title: "Mississippi", id: 24 },
  { title: "Missouri", id: 25 },
  { title: "Montana", id: 26 },
  { title: "Nebraska", id: 27 },
  { title: "Nevada", id: 28 },
  { title: "New Hampshire", id: 29 },
  { title: "New Jersey", id: 30 },
  { title: "New Mexico", id: 31 },
  { title: "New York", id: 32 },
  { title: "North Carolina", id: 33 },
  { title: "North Dakota", id: 34 },
  { title: "Ohio", id: 35 },
  { title: "Oklahoma", id: 36 },
  { title: "Oregon", id: 37 },
  { title: "Pennsylvania", id: 38 },
  { title: "Rhode Island", id: 39 },
  { title: "South Carolina", id: 40 },
  { title: "South Dakota", id: 41 },
  { title: "Tennessee", id: 42 },
  { title: "Texas", id: 43 },
  { title: "Utah", id: 44 },
  { title: "Vermont", id: 45 },
  { title: "Virginia", id: 46 },
  { title: "Washington", id: 47 },
  { title: "West Virginia", id: 48 },
  { title: "Wisconsin", id: 49 },
  { title: "Wyoming", id: 50 },
];

export const contactMedium = [
  { title: "WhatsApp", id: 1 },
  { title: "Teléfono", id: 2 },
];

const optionLists = {
  system: {
    dependent: false,
    params: { tipo: "sistema" },
  },
  platform: {
    dependent: false,
    params: { tipo: "plataforma" },
  },
  typing: {
    dependent: false,
    params: { tipo: "tipificacion" },
  },
  typingCause: {
    dependent: true,
    params: { tipo: "causa" },
  },
  country: {
    dependent: false,
    params: { tipo: "pais" },
  },
  state: {
    dependent: true,
    params: { tipo: "estado" },
  },
  location: {
    dependent: true,
    params: { tipo: "municipio" },
  },
  contactMedium: {
    dependent: false,
    params: { tipo: "medios" },
  },
};

export const getOptionList = (service, params) => {
  const formatedParams = {
    patificacion: params?.type,
  };
  if (service === "country") {
    return country;
  } else if (service === "platform") {
    return platform;
  } else if (service === "contactMedium") {
    return contactMedium;
  } else if (service === "state") {
    return params.country === 1 ? statesMexico : statesEU;
  } else if (service === "location") {
    return municipios[params.state] || [];
  } else {
    return axios({
      method: "post",
      url: baseUrl + "/catalogos/",
      timeout: 5000,
      params: { ...optionLists[service].params, ...formatedParams },
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        console.log("RESPUESTA", res.data.response);
        return res.data.response;
      })
      .then((arr) => {
        return arr?.map(
          ({
            descripcion,
            mediocontacto,
            pais,
            plataforma,
            sistema,
            tipificacion,
            causa,
            title,
            id,
          }) => ({
            title: descripcion || title,
            id:
              mediocontacto ||
              pais ||
              plataforma ||
              sistema ||
              causa ||
              tipificacion ||
              id,
          })
        );
      })
      .catch((err) => {
        console.error("rechazado", service, err);
      });
  }
};

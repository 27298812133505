import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF995B",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF995B",
    },
  },
});

theme.overrides = {
  MuiDialog: {},
  MuiDialogTitle: {
    root: {
      "& h6": {
        fontSize: "24px",
        fontWeight: "400",
        color: "#FF995B",
      },
      borderBottom: "1px solid #E7E7E7",
    },
  },
  MuiDialogContent: {
    root: {},
  },
  MuiDialogActions: {
    root: {
      borderTop: "1px solid #E7E7E7",
    },
  },
  MuiButton: {
    root: {
      textTransform: "none",
    },
    containedPrimary: {
      height: "40px",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#FF7C1B",
      },
    },
    textPrimary: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "17px",
      textDecoration: "underline",
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: "translate(21px, 23px) scale(1)",
    },
    shrink: {
      transform: "translate(13px, -6px) scale(0.75)",
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: "8px",
    },
    input: {},
  },
};

export default theme;

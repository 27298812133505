import Timeline from "@material-ui/lab/Timeline";
import SupportCaseTimelineEvent from "../controls/SupportConsoleControls/SupportCaseTimelineEvent";

const SupportCaseTimeline = ({ supportCaseData }) => {
  return (
    <Timeline>
      {supportCaseData?.map((currentEventItem, itemIndex) => {
        if (currentEventItem) {
          return (
            <SupportCaseTimelineEvent
              key={itemIndex}
              color={itemIndex}
              creationTime={currentEventItem["fecha_creacion"]}
              eventName={currentEventItem?.title}
              comments={currentEventItem["comentario"]}
              eventCreatorName={currentEventItem["id_usuario"]}
            />
          );
        }
      })}
    </Timeline>
  );
};

export default SupportCaseTimeline;

import React, { useEffect, useState } from "react";
import {
  SearchForm,
  Form,
} from "../controls/searchInteractionControls/SearchForm";
import Controls from "../controls/searchInteractionControls/Controls";
import Title from "../controls/createInteractionControls/Title";
import { Paper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useParams, useHistory } from "react-router-dom";
import Modal from "../Modal";
import {
  getAbrirCaso,
  getAgregarComentario,
  getValidarExistenciaUsuario,
  getOptionList,
} from "../../services";

export default function NewInteractionForm({ currentUserId, changeNivel }) {
  const { userId } = useParams();
  const siteNavigationHistory = useHistory();

  const [isModalActive, setIsModalActive] = useState(false);
  const [options, setOptions] = useState({});

  const isFieldDependent = {
    system: false,
    platform: false,
    typing: false,
    typingCause: true,
    country: false,
    state: true,
    location: true,
    contactMedium: false,
  };

  const paxStaffOrUser = (text) => {
    if (text.match(/^PAX/)) {
      return "PAX";
    } else if (text.match(/^[0-9]{10,12}/)) {
      return "Teléfono";
    } else {
      return "Staff";
    }
  };

  const fieldsInitialState = {
    paxStaff: "",
    system: "",
    platform: "",
    typing: "",
    typingCause: "",
    contactMedium: "",
    priority: "",
    phone: "",
    country: "",
    state: "",
    location: "",
  };

  const isFormValidHandler = (fieldValues = values) => {
    let temp = { ...errors };
    if ("paxStaff" in fieldValues) {
      temp.paxStaff = fieldValues.paxStaff ? "" : "Datos incompletos";
    }
    if ("system" in fieldValues)
      temp.system = fieldValues.system ? "" : "Datos incompletos";
    if ("platform" in fieldValues)
      temp.platform = fieldValues.platform ? "" : "Datos incompletos";
    if ("typing" in fieldValues)
      temp.typing = fieldValues.typing ? "" : "Datos incompletos";
    if ("typingCause" in fieldValues)
      temp.typingCause = fieldValues.typingCause ? "" : "Datos incompletos";
    if ("priority" in fieldValues)
      temp.priority = fieldValues.priority ? "" : "Datos incompletos";
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "Datos incompletos";
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "Datos incompletos";
    if ("location" in fieldValues)
      temp.location =
        fieldValues.location || fieldValues.country === 2
          ? ""
          : "Datos incompletos";
    if ("contactMedium" in fieldValues)
      temp.contactMedium = fieldValues.contactMedium ? "" : "Datos incompletos";
    if ("phone" in fieldValues) {
      temp.phone = !fieldValues.phone
        ? "Datos incompletos"
        : fieldValues.phone.length < 5
        ? "Mínimo 5 dígitos"
        : fieldValues.phone.length > 12
        ? "Máximo 12 dígitos"
        : "";
    }

    return temp;
  };

  const { values, setValues, errors, handleInputChange, resetForm, setErrors } =
    SearchForm(fieldsInitialState, true, isFormValidHandler);

  useEffect(() => {
    Object.keys(isFieldDependent).map(async (it) => {
      if (!isFieldDependent[it]) {
        const optArray = await getOptionList(it);
        console.log("option", it, optArray);
        setOptions((options) => {
          return {
            ...options,
            [it]: optArray,
          };
        });
      }
    });
  }, []);

  useEffect(async () => {
    if (values.typing) {
      const optArray = await getOptionList("typingCause", {
        type: values.typing,
      });
      setOptions((options) => ({
        ...options,
        typingCause: optArray,
      }));
    }
    setValues({ ...values, typingCause: fieldsInitialState.typingCause });
    console.log("optionsasdfasdf", options);
  }, [values.typing]);

  useEffect(async () => {
    if (values.country) {
      const optArray = await getOptionList("state", { ...values });
      setOptions((options) => ({
        ...options,
        state: optArray,
      }));
    }
    setValues({
      ...values,
      state: fieldsInitialState.state,
      location: fieldsInitialState.location,
    });
    setErrors({ ...errors, state: "", location: "" });
  }, [values.country]);

  useEffect(async () => {
    if (values.state) {
      const optArray = await getOptionList("location", { ...values });
      setOptions((options) => ({
        ...options,
        location: optArray,
      }));
    }
    setValues({
      ...values,
      location: fieldsInitialState.location,
    });
    setErrors({ ...errors, location: "" });
  }, [values.state]);

  const paxHandleInputChange = (ev) => {
    handleInputChange({
      ...ev,
      target: {
        name: ev.target.name,
        value: ev.target.value.replace(/-/g, ""),
      },
    });
  };

  const formatPaxInput = (text) => {
    return text
      .replace(/^([0-9]{2})([0-9]{1,10})$/, "$1-$2")
      .replace(/^([0-9]{2}-[0-9]{4})([0-9]{1,6})$/g, "$1-$2");
  };

  const phoneHandleInputChange = (ev) => {
    handleInputChange({
      ...ev,
      target: {
        name: ev.target.name,
        value: ev.target.value.replace(/[^0-9]/g, ""),
      },
    });
  };

  const formatPhoneInput = (text) => {
    return text
      .replace(/^([0-9]{2})([0-9]{1,11})$/, "$1-$2")
      .replace(/^([0-9]{2}-[0-9]{4})([0-9]{1,7})$/g, "$1-$2");
  };

  const isResetButtonDisabled = () => {
    return Object.keys(values).reduce(
      (acc, it) => acc && values[it] === "",
      true
    );
  };

  const submitInteraction = async () => {
    setErrors(isFormValidHandler(values));
    if (
      Object.keys(errors).reduce((acc, it) => acc && errors[it] === "", true)
    ) {
      try {
        const user = await (
          await getValidarExistenciaUsuario(values.paxStaff)
        ).data?.response["id_nuevo_elemento"];
        if (user != "-1") {
          try {
            const idNuevaIncidencia = await getAbrirCaso({
              user: user,
              userId: atob(userId),
              nivelUsuario: changeNivel.nivelUsuario,
              ...values,
            });
            try {
              await getAgregarComentario(
                idNuevaIncidencia,
                "Caso creado.",
                userId
              );
              siteNavigationHistory.push(
                `/console/${currentUserId}/case/${idNuevaIncidencia}`
              );
            } catch (e) {
              console.error(e);
            }
          } catch (e) {
            console.error(e);
          }
        } else {
          setErrors({ ...errors, paxStaff: "El usuario no existe" });
          setIsModalActive(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <Paper>
        <Form>
          <Title />
          <div style={{ display: "flex", margin: "27px 0", height: "75px" }}>
            <Controls.Inputs
              name="paxStaff"
              label="PAX, Staff o Teléfono"
              value={formatPaxInput(values.paxStaff)}
              onChange={paxHandleInputChange}
              error={errors.paxStaff}
              InputLabelProps={{ shrink: undefined }}
            />
            <Controls.Select
              name="system"
              label="Sistema"
              value={values.system}
              onChange={handleInputChange}
              options={options.system}
              error={errors.system}
            />
            <Controls.Select
              name="platform"
              label="Dispositivo"
              value={values.platform}
              onChange={handleInputChange}
              options={options.platform}
              error={errors.platform}
            />
            <Controls.Select
              name="typing"
              label="Tipificación"
              value={values.typing}
              onChange={handleInputChange}
              options={options.typing}
              error={errors.typing}
            />
          </div>
          <div style={{ display: "flex", margin: "27px 0", height: "75px" }}>
            <Controls.Select
              name="typingCause"
              label="Causa de Tipificación"
              value={values.typingCause}
              onChange={handleInputChange}
              options={options.typingCause}
              error={errors.typingCause}
              disabled={values.typing === ""}
            />
            <Controls.Select
              name="country"
              label="País"
              value={values.country}
              onChange={handleInputChange}
              options={options.country}
              error={errors.country}
            />
          </div>
          <div style={{ display: "flex", margin: "27px 0", height: "75px" }}>
            <Controls.Select
              name="state"
              label="Estado"
              value={values.state}
              onChange={handleInputChange}
              options={options.state}
              error={errors.state}
              disabled={values.country === ""}
            />
            <Controls.Select
              name="location"
              label="Municipio o Alcaldía"
              value={values.location}
              variant="outlined"
              onChange={handleInputChange}
              options={options.location}
              error={errors.location}
              disabled={values.state === "" || values.country === 2}
            />
          </div>
          <div style={{ display: "flex", margin: "27px 0", height: "75px" }}>
            <Controls.Select
              name="contactMedium"
              label="Medio de contacto"
              value={values.contactMedium}
              onChange={handleInputChange}
              options={options.contactMedium}
              error={errors.contactMedium}
            />
            <Controls.Inputs
              name="phone"
              label="Ingresa medio de contacto"
              value={formatPhoneInput(values.phone)}
              onChange={phoneHandleInputChange}
              error={errors.phone}
              InputLabelProps={{ shrink: undefined }}
            />
          </div>
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <Controls.Buttons
              text="Limpiar"
              onClick={resetForm}
              startIcon={<CancelIcon />}
              disabled={isResetButtonDisabled()}
            />
            <Controls.Buttons
              type="button"
              text="Enviar informacion"
              onClick={submitInteraction}
              startIcon={<CheckCircleIcon />}
            />
          </div>
        </Form>
        <Modal
          isOpen={isModalActive}
          handleClose={() => setIsModalActive(false)}
          titleText={"Información incorrecta"}
        >
          {paxStaffOrUser(values.paxStaff)} no válido, revisa la información
          ingresada.
        </Modal>
      </Paper>
    </>
  );
}

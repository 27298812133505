import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SupportCaseTimeline from "./SupportCaseTimeline";
import {useEffect, useState} from "react";
import useFetch from "use-http";
import {useParams} from "react-router-dom";
import {getSupportCaseHistory} from "../../services/index";
const SupportCaseTimelineBlock = ({supportCaseId, changeActualizarTimeline}) => {

    // COMMENT **************************************************
    //              useFetch configuration and objects loading
    //         **************************************************

    // COMMENT -------------> Component's state objects
    const baseurl = process.env.REACT_APP_API_URL;
    const axios = require('axios');
    const [sampleSupportCaseHistory, setSampleSupportCaseHistory] = useState([]);
    const [supportCaseHistory, setSupportCaseHistory] = useState([]);
    // COMMENT -------------> useFetch configuration
    const {get, post, loading, response, error} = useFetch(baseurl, { cachePolicy: "no-cache",
                                                                                                                        retries: 3,
                                                                                                                        retryDelay: 1000,
                                                                                                                        timeout: 60000,
                                                                                                                        onAbort: () => {
                                                                                                                            console.log("<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> Aborting the current request by user...");
                                                                                                                        },
                                                                                                                        onError: ({error}) => {
                                                                                                                            console.error(`<SearchSupportCasesForm@GlobalConfigurationObject#onError> The request could not be completed and the last retry was wasted... [Details: ${error}`);
                                                                                                                        },
                                                                                                                        onTimeOut: () => {
                                                                                                                            console.log("<SearchSupportCasesForm@GlobalConfigurationObject#onAbort> The max-timeout was reached, the request will be cancelled...");
                                                                                                                        },
                                                                                                                        responseType: "json",
                                                                                                                        suspense: true  });

    const loadSampleSupportCaseHistory = async() => {
        const retrievedOptions = await post(`incidencia/comentarios`);

        if (response != error && response.ok ) {
            setSampleSupportCaseHistory(retrievedOptions?.response.map((el) => {
                    if (el.indicencia == supportCaseId) {
                        return Object.assign({}, {
                            creationTime: el.fechaCreacion,
                            title: "Evento",
                            comments: el.comentario,
                            creatorsName: el.idUsuario
                        });
                    }
                })
            )
        }
    }

    useEffect(() => {
        console.log("TL ===> "+changeActualizarTimeline.actualizarTimeline)
        if (changeActualizarTimeline.actualizarTimeline == true){
            console.log("creo que se actualizara el componente TL");
            changeActualizarTimeline.setActualizarTimeline(false);
        }
        async function loadSupportCaseHistory(){
            const response = await getSupportCaseHistory(supportCaseId);
            if (response != error && response.status === 200){
                console.log(response.data.response[0]);
                setSupportCaseHistory(response.data.response)
                return response
            }
        }
        loadSupportCaseHistory()
    }, [changeActualizarTimeline.actualizarTimeline]);

    return (
        <Paper>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Typography variant="h5" color="primary">
                            Historial de seguimiento
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="column" justify="center" alignItems="center">
                    <Grid item xs={10}>
                        <SupportCaseTimeline supportCaseData={supportCaseHistory} />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default SupportCaseTimelineBlock;

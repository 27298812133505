import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    backdrop: {
        zIndex: 2001,
        color: "#FFF"
    },
    loaderText: {
        marginBottom: "10px"
    },
    loaderProgress: {
        color: "#FFF"
    }
});

const ControlLoader = () => {

    const classes = useStyles();

    return (
        <>
            <Backdrop className={classes.backdrop} open={true} onClick={() => {}}>
                <Grid container direction="column" justify="center" alignItems="stretch" spacing={4}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={0} className={classes.loaderText}>
                        <Typography variant="h4" style={{ color: '#FFF'}}>
                            Cargando elementos...
                        </Typography>
                    </Grid>
                   <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                       <CircularProgress variant="indeterminate" className={classes.loaderProgress}/>
                   </Grid>
                </Grid>
            </Backdrop>
        </>
    )
}

export default ControlLoader;

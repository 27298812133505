import {
  Dialog,
  Grid,
  DialogTitle,
  Button,
  makeStyles,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MuiDialogTitle: {
    borderBottom: "1px solid #E7E7E7",
  },
}));

const Modal = ({ isOpen, handleClose, titleText, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-cerrar"
      style={{ paper: { width: "90%" } }}
    >
      <DialogTitle disableTypography={false}>
        <Typography variant="h6" align="center">
          {titleText}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ padding: "30px" }}>
        <DialogContentText style={{ margin: 0 }}>
          <Typography align="center" style={{ color: "black" }}>
            {children}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "17px 30px" }}>
        <Grid container justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleClose}>
            Entendido
          </Button>
          <Button color="primary" onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

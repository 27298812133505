import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import DialpadIcon from "@material-ui/icons/Dialpad";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import ClearIcon from "@material-ui/icons/Clear";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import AssignmentIcon from "@material-ui/icons/Assignment";
const GetChoosenIconType = ({ iconType, detail }) => {
  switch (iconType) {
    case "user":
      return <AccountCircleIcon />;
    case "phone":
      return <PhoneIcon />;
    case "E-mail":
      return <AlternateEmailIcon />;
    case "dial":
      return <DialpadIcon />;
    case "status":
      return (
        <DataUsageIcon
          style={{ color: detail === "Activo" ? "#66BB6A" : "#D33604" }}
        />
      );
    case "nivel":
      return <AssignmentIndIcon />;
    case "folio":
      return <AssignmentIcon />;
    default:
      return <ClearIcon />;
  }
};

const SingleUserInfoBlock = ({ iconType, title, detail }) => {
  return (
    <>
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item>
          <GetChoosenIconType iconType={iconType} detail={detail} />
        </Grid>
        <Grid item container>
          <Grid item container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h6" noWrap={true} gutterBottom>
                {title}
              </Typography>
              <Typography variant="subtitle1" noWrap={true} gutterBottom>
                {detail}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SingleUserInfoBlock;

import ComposedAccordionItem from "./ComposedAccordionItem";
import UserInfoDetails from "./UserInfoDetails";
import TaskPaymentDetails from "./TaskPaymentDetails";
import MoreInfoDetails from "./MoreInfoDetails";

const getUserInfoPanel = (itemIndex, title, userId) => {
    return <ComposedAccordionItem itemIndex={itemIndex} title={title} details={ <UserInfoDetails userId={userId} /> } />
}

const getTaskAndPaymentsPanel = (itemIndex, title, LocalFuzzyTableContext) => {
    return <ComposedAccordionItem itemIndex={itemIndex} title={title} details={ <TaskPaymentDetails LocalFuzzyTableContext={LocalFuzzyTableContext}/> } />
}

const getMoreInfoPanel = (itemIndex, title, userId, changeActualizarIformacionAdicionalm,changeEstadoCasom) => {
    return <ComposedAccordionItem itemIndex={itemIndex} title={title} details={ <MoreInfoDetails userId={userId} changeActualizarIformacionAdicional = {changeActualizarIformacionAdicionalm} changeEstadoCaso={ changeEstadoCasom}/> } />
}

export {
    getUserInfoPanel,
    getTaskAndPaymentsPanel,
    getMoreInfoPanel
};

import Select from "./Select";
import Buttons from "./Buttons";
import DatePicker from "./DatePicker";
import Inputs from "./Inputs";

const Controls = {
    Buttons,
    DatePicker,
    Select,
    Inputs,
}

export default Controls;
import React, { Suspense, useState, useMemo, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SingleUserInfoBlock from "../controls/SupportConsoleControls/SingleUserInfoBlock";
import { makeStyles } from "@material-ui/core/styles";
import useFetch from "use-http";

import { getGeneralData } from "../../services/index";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  leftSpacer: {
    marginLeft: "1em",
  },
  centeredElement: {
    width: "65%",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  column: {},
});

const phoneFormat = (phone) => {
  return `${phone.slice(0, 2)}-${phone.slice(2, 6)}-${phone.slice(6)}`;
};

const UserInfoBlock = ({ changeActualizarInfoBlock }) => {
  const { supportCaseId } = useParams();

  const { get, post, loading, response, error } = useFetch();

  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [pax, setPax] = useState("");
  const [estado, setEstado] = useState("");
  const [nivel, setNivel] = useState("");

  useEffect(() => {
    if (changeActualizarInfoBlock.actualizarInfoBlock == true) {
      changeActualizarInfoBlock.setActualizarInfoBlock(false);
    }
    async function loadGeneralData() {
      const response = await getGeneralData(supportCaseId);
      if (response != error && response.status == 200) {
        if (response.data.response.length > 0) {
          setNombre(response.data.response[0]["nombre"]);
          setTelefono(response.data.response[0]["celular"]);
          setEmail(response.data.response[0]["correo"]);
          setPax(response.data.response[0]["usuario"]);
          setEstado(response.data.response[0]["estatus"]);
          setNivel(response.data.response[0]["nivel"]);
          return response;
        }
      }
    }
    loadGeneralData();
  }, [changeActualizarInfoBlock.actualizarInfoBlock]);

  const classes = useStyles();

  return (
    <>
      <Paper classes={classes.paper}>
        <div className={classes.paper}>
          <div className={classes.centeredElement}>
            <div className={classes.column}>
              <Grid item>
                <SingleUserInfoBlock
                  iconType="user"
                  title="Nombre usuario"
                  detail={nombre}
                />
              </Grid>
              <Grid item>
                <SingleUserInfoBlock
                  iconType="status"
                  title="Estatus usuario"
                  detail={estado}
                />
              </Grid>
              <Grid item>
                <SingleUserInfoBlock
                  iconType="nivel"
                  title="Nivel"
                  detail={nivel}
                />
              </Grid>
            </div>
            <div className={classes.column}>
              <Grid item>
                <SingleUserInfoBlock
                  iconType="folio"
                  title="Folio"
                  detail={supportCaseId}
                />
              </Grid>
              <Grid item>
                <SingleUserInfoBlock iconType="dial" title="PAX" detail={pax} />
              </Grid>
            </div>
            <div className={classes.column}>
              <Grid item>
                <SingleUserInfoBlock
                  iconType="E-mail"
                  title="E-mail"
                  detail={email}
                />
              </Grid>
              <Grid item>
                <SingleUserInfoBlock
                  iconType="phone"
                  title="Teléfono"
                  detail={phoneFormat(telefono)}
                />
              </Grid>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default UserInfoBlock;
